import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "sidemenu" ]

  show() {
    let menu = document.getElementById('side-menu')
    menu.classList.add('absolute', 'bg-white', 'right-0')
    menu.classList.remove('hidden')
  }

  hide() {
    let menu = document.getElementById('side-menu')
    menu.classList.remove('absolute', 'bg-white', 'right-0')
    menu.classList.add('hidden')
  }
}
