import { Controller } from "@hotwired/stimulus"
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static targets = ["form", "input"]

  query(){
    this.submit()
  }

  submit() {
    setTimeout(() => {
      let url = new URL(window.location.href);
      url.searchParams.delete('q')

      url.searchParams.append("q", this.inputTarget.value);
      window.location.href = url.toString()
      this.formTarget.requestSubmit()
    }, 700);
  }
}
