import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { account: String, id: String, name: String }

  fillGa() {
    document.getElementById('instance_ga_info_name').value = this.nameValue
    document.getElementById('instance_ga_info_id').value = this.idValue
    document.getElementById('instance_ga_info_account_id').value = this.accountValue
  }
}
