import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  focus(event) {
    const input = event.target;
    const length = input.value.length;

    input.setSelectionRange(length, length);
  }
}
