import {Controller} from '@hotwired/stimulus'
import {get} from "@rails/request.js";

export default class extends Controller {
  static targets = [ "groupCheckbox", "revealButton", "selectAllCheckbox", "groupsContainer", "copyGroupsField", "submit", "select", "defaultOption", "settings", "roundingSettings", "menu" ]
  static values = {
    action: String,
    base: String
  }

  OPACITY_CLASS = ['pointer-events-none', 'opacity-50']

  initialize() {
    if (this.hasSelectTarget) {
      this.selectTarget.required = true
    }
  }

  updateAll() {
    let fragment = document.createDocumentFragment(); // for optimization

    for (const checkbox of this.groupCheckboxTargets) {
      if (this.selectAllCheckboxTarget.checked ^ checkbox.checked) {
        checkbox.checked = !checkbox.checked
        const input = this.createOrRemoveId(checkbox)
        if (input) {
          fragment.appendChild(input)
        }
      }
    }

    this.groupsContainerTarget.appendChild(fragment)
  }

  update(event) {
    const input = this.createOrRemoveId(event.target)
    if (input) {
      this.groupsContainerTarget.appendChild(input)
    }
    this.selectAllCheckboxTarget.checked = this.allGroupCheckboxesChecked()
  }

  createOrRemoveId(checkbox) {
    if (checkbox.checked) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.value = checkbox.dataset.groupId
      input.id = `group_${checkbox.dataset.groupId}`
      input.name = 'groups[]'

      return input
    } else {
      document.getElementById(`group_${checkbox.dataset.groupId}`).remove()
    }
  }

  allGroupCheckboxesChecked() {
    let retVal
    this.groupCheckboxTargets.every(checkbox => {
      return retVal = checkbox.checked
    })
    return retVal
  }

  toggle() {
    this.toggleSettings()
    this.repaintButton()
    this.toggleCheckboxes()
    this.toggleCopyGroupsFields()
  }

  repaintButton() {
    if (this.revealButtonTarget.classList.contains("btn-gray-pushed")) {
      this.revealButtonTarget.classList.replace("btn-gray-pushed", "btn-gray")
    } else {
      this.revealButtonTarget.classList.replace("btn-gray", "btn-gray-pushed")
    }
  }

  // This is toggled manually and not via reveal component as rest of elements, because it would interfere with another
  // reveal scope
  toggleCheckboxes() {
    this.groupCheckboxTargets.forEach(checkbox => {
      if (this.settingsTarget.classList.contains("hidden")) {
        checkbox.classList.add("hidden")
      } else {
        checkbox.classList.remove("hidden")
      }
    })
  }

  toggleSettings() {
    if (this.settingsTarget.classList.contains('hidden')) {
      this.settingsTarget.classList.remove('hidden')
      this.menuTarget.innerHTML = this.actionValue
    } else {
      this.hide()
      this.menuTarget.innerHTML = this.baseValue
    }
  }

  hide() {
    this.settingsTarget.classList.add('hidden')
  }

  toggleCopyGroupsFields() {
    this.copyGroupsFieldTargets.forEach(input => {
      input.value = !(input.value === "true")
    })
  }

  toggleLoading() {
    if (this.selectTarget.value !== '') {
      if (this.submitTarget.classList.contains(this.OPACITY_CLASS[0])) {
        this.submitTarget.classList.remove(...this.OPACITY_CLASS)
      } else {
        this.submitTarget.classList.add(...this.OPACITY_CLASS)
      }
    }
  }

  async enableCopy() {
    if (this.hasRevealButtonTarget) {
      const url = new URL(window.location.href + '/copy_groups_status')
      const response = await get(url.href)

      if (response.ok) {
        try {
          const json = await response.json
          if (json.status === 'no_copy_in_progress') {
            this.revealButtonTarget.classList.replace("btn-disabled", "btn-gray")
          }
        } catch (error) {
          if (this.hasRevealButtonTarget) {
            this.revealButtonTarget.classList.replace("btn-disabled", "btn-gray")
          }
        }
      }
    }
  }

  selectChange() {
    if (this.hasDefaultOptionTarget) {
      this.selectTarget.removeChild(this.defaultOptionTarget)
    }
  }
}
