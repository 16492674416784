import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  scroll() {
    var scroll = parseInt(window.pageYOffset)
    if (scroll > 50) {
      this.element.classList.add('shadow-dark-md')
    } else {
      this.element.classList.remove('shadow-dark-md')
    }
  }
}
