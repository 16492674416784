import { Controller } from '@hotwired/stimulus'
import { nanoid } from 'nanoid'
import zip from 'lodash/zip'

export default class extends Controller {
  static targets = [ "template", "container", "item", "competitorInfo" ]

  connect() {
    this.updateCompetitorsInfoVisibility()
  }

  add(event) {
    const conditionType = event.target.dataset.type
    const template = this.findTemplate(conditionType)
    const html = template.innerHTML.replace(/PLACEHOLDER/g, nanoid())
    const container = this.findContainer(conditionType)
    container.insertAdjacentHTML('beforeend', html)
    this.updateCompetitorsInfoVisibility()
  }

  duplicate(event) {
    const source = this.findItem(event)
    const html = source.outerHTML.replace(new RegExp(source.dataset.index, 'g'), nanoid())
    source.insertAdjacentHTML('afterend', html)
    const copy = source.nextSibling

    // match field values in the copy with field values in the source
    zip(source.querySelectorAll('input,select'), copy.querySelectorAll('input,select'))
      .forEach(([sourceField, copyField]) => { copyField.value = sourceField.value })
    this.updateCompetitorsInfoVisibility()
  }

  remove(event) {
    const item = this.findItem(event)
    item.remove()
    this.updateCompetitorsInfoVisibility()
  }

  findItem(event) {
    return this.itemTargets.find(item => item.contains(event.target))
  }

  findTemplate(type) {
    return this.templateTargets.find(template => template.dataset.type === type)
  }

  findContainer(type) {
    return this.containerTargets.find(container => container.dataset.type === type)
  }

  updateCompetitorsInfoVisibility() {
    let info_elements = document.querySelectorAll('[data-conditions-target="competitorInfo"]')
    let conditionsCount = document.querySelectorAll('[data-conditions-target="item"]').length
    if (conditionsCount > 0) {
      info_elements.forEach((element) => element.classList.remove('hidden'))
    } else {
      info_elements.forEach((element) => element.classList.add('hidden'))
    }
  }
}
