import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "sort", "order" ]

  interceptRequest() {
    if (this.hasOrderTarget) {
      this.orderTarget.remove()
    }
    if (this.hasSortTarget) {
      this.sortTarget.remove()
    }
  }
}
