import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "input", "reorderContainer" ]
  static values = {
    form: String // form ID, if container target is outside of desired form
  }

  update() {
    this.reorderContainerTarget.innerHTML = ""
    this.inputTargets.forEach(input => {
      input.querySelectorAll('input[type=hidden]').forEach(hiddenInput => {
        let node = hiddenInput.cloneNode()
        if (this.formValue) {
          node.setAttribute("form", this.formValue)
        }
        this.reorderContainerTarget.appendChild(node)
      })
    })
  }
}
