import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["palette", "input", "macOs", "otherOs", 'button', "background", "results"]

  macOsTargetConnected() {
    if (navigator.platform.indexOf("Mac") === 0) {
      this.otherOsTarget.classList.add("hidden")
    }
    else {
      this.macOsTarget.classList.add("hidden")
    }
  }

  isVisible() {
    return !this.paletteTarget.className.includes("hidden")
  }

  toggle(event) {
    if (navigator.platform.indexOf("Mac") === 0) {
      if (event.metaKey && event.key === "k") {
        this.paletteTarget.classList.toggle("hidden")
        this.backgroundTarget.classList.toggle("hidden")

        if (this.isVisible()) {
          this.inputTarget.value = ""
          this.inputTarget.focus()
        }
      }
    }
    else if (event.ctrlKey && event.key === "k") {
      this.paletteTarget.classList.toggle("hidden")
      this.backgroundTarget.classList.toggle("hidden")

      if (this.isVisible()) {
        this.inputTarget.value = ""
        this.inputTarget.focus()
      }
    }
  }

  close(event) {
    if (event.keyCode === 27) {
      this.paletteTarget.classList.add("hidden")
      this.backgroundTarget.classList.add("hidden")
    }
  }

  productClose() {
    this.paletteTarget.classList.add("hidden")
    this.backgroundTarget.classList.add("hidden")
  }

  manualToggle() {
    this.paletteTarget.classList.toggle("hidden")
    this.backgroundTarget.classList.toggle("hidden")
    let menuClassList = document.getElementById("side-menu").classList

    if (!menuClassList.contains("hidden")) {
      menuClassList.add("hidden")
      menuClassList.remove('absolute', 'bg-white', 'right-0')
    }

    if (this.isVisible()) {
      this.inputTarget.value = ""
      this.inputTarget.focus()
    }

    this.hideResults()
  }

  hideResults(){
    if (!this.inputTarget.value && this.hasResultsTarget){
      this.resultsTarget.classList.add("hidden")
    }
  }
}
