import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["range", "rangeGroup", "label", "parent"];

  toggle(e) {
    // In case user click to SVG or PATH of svg
    // <svg>
    // <path></svg>
    // </svg>
    // this.abc(selectedRanges, targetElement.id, from, to)
    const targetElement = e.target.tagName.toUpperCase() === "PATH" ? e.target.parentElement : e.target;
    if (targetElement.classList.contains("text-gray-400") === true ) return

    const parentDiv = targetElement.closest('div[data-setting-roundings-target="rangeGroup"]');
    const from = parentDiv.querySelector('input[data-setting-roundings-target="from"]').value;
    const to = parentDiv.querySelector('input[data-setting-roundings-target="to"]').value;

    this.handleRangeAvailability(targetElement.id, from, to);
  }

  handleRangeAvailability(skipIndex, from, to){
    this.rangeGroupTargets.forEach(rangeGroup => {
      const rangeIcon = rangeGroup.querySelector(".icon");
      if ( rangeIcon.id === skipIndex) return false;
      if ( !rangeIcon.classList.contains("text-ds-blue")) return false;

      const rangeGroupFrom = Number(rangeGroup.getElementsByTagName('input')[0].value);
      const rangeGroupTo = Number(rangeGroup.getElementsByTagName('input')[1].value);

      if (this.rangesOverlap(rangeGroupFrom, rangeGroupTo, from, to)) {
        this.disableIcon(rangeIcon);
      }
    })
  }

  validateRange(e) {
    const match = this.extractRangeInfo(e.target.name);
    if (!match) return;

    const icon = document.getElementById(`svg_${match.index}`);
    if (!icon.classList.contains("text-ds-blue")) return;

    const { from, to } = this.getRangeValues(e.target, match.type, match.index);
    this.checkRangeOverlapping(match.index, from, to);
  }

  extractRangeInfo(name) {
    const match = name.match(/\[([0-9]+)\]\[([a-z]+)\]/);
    if (!match) return null;
    return { index: match[1], type: match[2] };
  }

  getRangeValues(target, type, index) {
    const fromField = document.getElementsByName(`instance[rounding_ranges][${index}][from]`)[0];
    const toField = document.getElementsByName(`instance[rounding_ranges][${index}][to]`)[0];
    
    return type === 'to' ? { from: fromField.value, to: target.value } : { from: target.value, to: toField.value };
  }

  checkRangeOverlapping(skipIndex, from, to) {
    this.rangeGroupTargets.forEach(rangeGroup => {
      const rangeIcon = rangeGroup.querySelector(".icon");
      if ( !rangeIcon.classList.contains("text-ds-blue") || rangeIcon.id === `svg_${skipIndex}`) return false;

      const rangeGroupFrom = Number(rangeGroup.getElementsByTagName('input')[0].value);
      const rangeGroupTo = Number(rangeGroup.getElementsByTagName('input')[1].value);

      if (this.rangesOverlap(rangeGroupFrom, rangeGroupTo, from, to)) this.disableIcon(rangeIcon);
    });
  }

  disableIcon(icon) {
    icon.previousElementSibling.value = "false";
    icon.classList.remove("text-ds-blue");
    icon.classList.add("text-gray-400");
    icon.setAttribute("fill", "none");
  }

  rangesOverlap(from1, to1, from2, to2) {
    return from1 < to2 && from2 < to1;
  }

  rangeOverlappingSelectedInOnePoint(from1, to1, from2, to2) {
    return to1 === from2 || from1 === to2;
  }
}
