import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    "container", "field", "template", "label", "arrowUp", "arrowDown", 'exportSchedule',
    'exportScheduleAt', 'exportRepeatScheduleAt', 'scheduleActive', 'scheduleHour', 'scheduleRepeatHour',
    'scheduleMinute', 'timeSelect', 'timeSelectLabel', 'repeatSelect', 'scheduleSelect', 'exportRemoveProducts',
    'onlyChanged', 'marketingRemoved', 'manualRemoved', 'exportRemoveProductsGroup'
  ]

  connect() {
    if (this.exportScheduleAtTarget.value) {
      if (this.exportScheduleAtTarget.value == "0 */2 * * *") { // every even hour
        this.scheduleSelectTarget.value = "0 */2 * * *"
        this.timeSelectTarget.classList.add("hidden")
        this.repeatSelectTarget.classList.add("hidden")
      } else {
        this.scheduleHourTarget.value = this.exportScheduleAtTarget.value.substring(3, 5)
        this.scheduleMinuteTarget.value = this.exportScheduleAtTarget.value.substring(0, 2)
        if (this.exportRepeatScheduleAtTarget.value) { // twice a day
          this.scheduleSelectTarget.value = "2 0 * * *"
          let diff = Number(this.exportRepeatScheduleAtTarget.value.split(' ')[1]) -
              Number(this.exportScheduleAtTarget.value.split(' ')[1])
          if (diff < 0) {
            diff += 24
          }
          this.scheduleRepeatHourTarget.value = diff.toString()
        } else { // once a day
          this.scheduleSelectTarget.value = "1 0 * * *"
          this.repeatSelectTarget.classList.add("hidden")
          this.timeSelectLabelTarget.classList.add("hidden")
        }
      }
    } else {
      this.timeSelectTarget.classList.add("hidden")
      this.repeatSelectTarget.classList.add("hidden")
    }
    if (document.documentElement.clientWidth < 640) {
      this.hideCompetitors()
    }
  }

  hideCompetitors() {
    this.containerTarget.classList.toggle("hidden")
    if (this.containerTarget.classList.contains("hidden")) {
      this.arrowUpTarget.classList.add("hidden")
      this.arrowDownTarget.classList.remove("hidden")
    } else {
      this.arrowUpTarget.classList.remove("hidden")
      this.arrowDownTarget.classList.add("hidden")
    }
  }

  setExportSchedule() {
    if (this.scheduleSelectTarget.value === "0 */2 * * *") { // every even hour
      this.exportScheduleAtTarget.value = "0 */2 * * *"
      this.timeSelectTarget.classList.add("hidden")
      this.repeatSelectTarget.classList.add("hidden")
      this.exportRepeatScheduleAtTarget.value = ""
    } else {
      this.exportScheduleAtTarget.value = this.cronTime
      this.timeSelectTarget.classList.remove("hidden")
      if (this.scheduleSelectTarget.value === "1 0 * * *") { // once a day
        this.repeatSelectTarget.classList.add("hidden")
        this.timeSelectLabelTarget.classList.add("hidden")
        this.exportRepeatScheduleAtTarget.value = ""
      } else if (this.scheduleSelectTarget.value === "2 0 * * *") { // twice a day
        this.repeatSelectTarget.classList.remove("hidden")
        this.exportRepeatScheduleAtTarget.value = this.cronRepeatTime
        this.timeSelectLabelTarget.classList.remove("hidden")
      }
    }
  }

  setExportScheduleActive() {
    if (this.scheduleActiveTarget.checked) {
      if (!this.exportScheduleAtTarget.value) {
        this.exportScheduleAtTarget.value = "0 */2 * * *"
      }
      this.scheduleSelectTarget.classList.remove("hidden")
      if (this.exportScheduleAtTarget.value != "0 */2 * * *") {
        this.timeSelectTarget.classList.remove("hidden")
        if (this.exportRepeatScheduleAtTarget.value) {
          this.repeatSelectTarget.classList.remove("hidden")
        }
      } else {
        this.timeSelectTarget.classList.add("hidden")
        this.repeatSelectTarget.classList.add("hidden")
      }
    } else {
      this.scheduleSelectTarget.classList.add("hidden")
      this.timeSelectTarget.classList.add("hidden")
      this.repeatSelectTarget.classList.add("hidden")
      this.exportRepeatScheduleAtTarget.value = ""
    }
  }

  toggleExportSchedule() {
    this.timeSelectTarget.classList.toggle("hidden")
    this.exportScheduleTarget.classList.toggle("hidden")
    if (this.exportScheduleTarget.value != "0 */2 * * *") {
     this.timeSelectTarget.classList.remove("hidden")
    }
  }

  setOnlyChanged() {
    if (this.onlyChangedTarget.checked) {
      this.exportRemoveProductsTarget.classList.remove('hidden')
      this.exportRemoveProductsGroupTarget.classList.remove('hidden')
    } else {
      this.exportRemoveProductsTarget.classList.add('hidden')
      this.exportRemoveProductsGroupTarget.classList.add('hidden')
      this.marketingRemovedTarget.checked = false
      this.manualRemovedTarget.checked = false
    }
  }

  enter(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
      this.addCompetitor()
    }
  }

  addCompetitor() {
    let competitorMap = []
    this.labelTargets.map((element) => {
      competitorMap.push(element.value)
    })
    let competitorName = this.fieldTarget.value.trim().split(" ").join("").toLowerCase()
    if (competitorMap.includes(competitorName)) return this.fieldTarget.value = "";
    if (competitorName === '') return;
    let competitorItem = this.templateTarget.innerHTML.replace(/PLACEHOLDER/g, competitorName)
    this.containerTarget.insertAdjacentHTML('afterbegin', competitorItem)
    this.fieldTarget.value = ""
  }

  removeCompetitor(event) {
    let button = event.target.closest(".competitors")
    let wrapper = event.target.closest(".competitor-field")
    wrapper.classList.remove("hover:text-red-600")
    wrapper.classList.add("line-through", "text-red-600")
    button.classList.remove("hover:text-red-600", "text-gray-400")
    button.classList.add("pointer-events-none", "text-gray-200")
    let input = wrapper.querySelector("input")
    input.remove()
  }

  removeTemporaryCompetitor(event) {
    let competitor = event.target.closest(".competitor-field")
    competitor.remove()
  }

  onlyChangedZeroValue(event) {
    if (event.target.value == '') {
      event.target.classList.add("border-1", "border-red-600")
    } else {
      event.target.classList.remove("border-1", "border-red-600")
    }
  }

  get cronTime() {
    return [this.minutes, this.hours, '* * *'].join(' ')
  }

  get cronRepeatTime() {
    let hours = (Number(this.hours) + Number(this.repeatHours)) % 24
    return [this.minutes, hours, '* * *'].join(' ')
  }

  get minutes() {
    return this.scheduleMinuteTarget.value
  }

  get hours() {
    return this.scheduleHourTarget.value
  }

  get repeatHours() {
    return this.scheduleRepeatHourTarget.value
  }
}
