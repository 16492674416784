import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "source", "confirmation" ]

  copy() {
    this.sourceTarget.select()
    document.execCommand('copy')
    this.showConfirmation()
  
    setTimeout(() => {
      this.hideConfirmation()
    }, 2000);
  }

  showConfirmation() {
    this.confirmationTarget.classList.remove("hidden")
    this.confirmationTarget.classList.add("block")
  }

  hideConfirmation() {
    this.confirmationTarget.classList.remove("block")
    this.confirmationTarget.classList.add("hidden")
  }
}
