import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', "toggle", 'allProductsView', 'specialistSelect', 'regenerateBadge', 'regenerateButton', 'regenerateTooltip', 'selectionButton']

  connect() {
    this.switchApproved()

    let url = new URL(location.href)
    let specialitsId = url.searchParams.get('pricing_specialist_id')
    this.selectPricingSpecialist(specialitsId)

    addEventListener('turbo:before-stream-render', (e) => { this.disableSelection(e) })
  }

  updateMode() {
    if (this.isApproved()) {
      this.switchApproved()
    } else {
      this.switchAll()
    }
    this.formTarget.requestSubmit()
  }

  showRegeneration() {
    this.regenerateBadgeTarget.classList.remove('hidden')
    this.regenerateButtonTarget.classList.remove('hidden')
    if (this.hasRegenerateTooltipTarget) {
      this.regenerateTooltipTarget.classList.remove('hidden')
    }
  }

  switchApproved() {
    this.allProductsViewTarget.value = true
  }

  switchAll() {
    this.allProductsViewTarget.value = false
  }

  isApproved() {
    return this.toggleTarget.checked
  }

  selectOption(e) {
    const value = e.target.value
    this.selectPricingSpecialist(value)
    
    this.formTarget.requestSubmit()
  }

  selectPricingSpecialist(value) {
    for (let option of this.specialistSelectTarget.options) {
      if (option.value == value) {
        option.selected = true
      }
    }
  }

  disableSelection(e) {
    if (e.target.getAttribute('target') === 'disable_selection') {
      this.selectionButtonTargets.forEach(button => {
        button.disabled = true
      })
    } else if (e.target.getAttribute('target') === 'enable_selection') {
      this.selectionButtonTargets.forEach(button => {
        button.disabled = false
      })
    }
  }

  disableSelectionButton(e) {
    e.target.value = e.target.dataset.disableWith
  }
}
