import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation'
import { format, intervalToDuration, parse, parseISO } from 'date-fns'
import { enUS, cs, de } from 'date-fns/locale'
import 'chartjs-adapter-date-fns'
import uniq from 'lodash/uniq'

Chart.register(annotationPlugin)

export default class extends Controller {
  static targets = ['chart']
  static values = {
    triggers: Array,
    stats: Object,
    triggersByDate: Object,
    footers: Object,
    maxCount: Number,
    chartUrl: String,
    from: String,
    to: String,
    currency: String,
    userLocale: String,
    translationFooters: String,
    translationBasicStats: String,
    translationCosts: String,
    translationProfit: String,
    translationRevenue: String,
    translationMargin: String,
  }

  connect() {
    let triggers = this.triggersValue
    let basicStatsChart = this.translationBasicStatsValue
    let footers = this.footersValue
    let translationFooters = this.translationFootersValue
    let translationMargin = this.translationMarginValue
    let currency = this.currencyValue
    const locales = { cs: cs, en: enUS, de: de }
    const userLocale = this.userLocaleValue
    const fromDate = new Date(this.fromValue)
    const toDate = new Date(this.toValue)

    let chart = new Chart(this.chartTarget, {
      type: 'line',
      spanGaps: false,
      data: {
        datasets: [
          {
            label: '\xa0', // this ensures that the label text is not displayed in the tooltip
            data: this.getTriggers(),
            pointBackgroundColor: 'rgb(2, 132, 199)',
            fill: false,
            pointRadius: 7,
            borderWidth: 0,
          },
          {
            label: this.translationRevenueValue,
            data: this.getMetrics('revenue'),
            fill:  'origin',
            borderWidth: 2,
            borderColor: 'rgb(128, 90, 213)',
            backgroundColor: 'rgba(128, 90, 213, 0.1)',
            pointRadius: 0,
            pointHitRadius: 20,
            pointHoverRadius: 7,
            pointBackgroundColor: 'rgba(128, 90, 213, 0.2)',
            borderJoinStyle: 'round',
            yAxisID: 'y1',
            tension: 0.2,
          },
          {
            label: this.translationCostsValue,
            data: this.getMetrics('costs'),
            fill: 'origin',
            borderWidth: 2,
            borderColor: 'rgb(213, 63, 140)',
            backgroundColor: 'rgba(213, 63, 140, 0.1)',
            pointRadius: 0,
            pointHitRadius: 20,
            pointHoverRadius: 7,
            pointBackgroundColor: 'rgba(213, 63, 140, 0.2)',
            borderJoinStyle: 'round',
            yAxisID: 'y1',
            tension: 0.2,
          },
          {
            label: this.translationProfitValue,
            data: this.getMetrics('profit'),
            fill: 'origin',
            borderWidth: 2,
            borderColor: 'rgb(49, 130, 206)',
            backgroundColor: 'rgba(49, 130, 206, 0.1)',
            pointRadius: 0,
            pointHitRadius: 20,
            pointHoverRadius: 7,
            pointBackgroundColor: 'rgba(49, 130, 206, 0.2)',
            borderJoinStyle: 'round',
            yAxisID: 'y1',
            tension: 0.2,
          },
          {
            label: this.translationMarginValue,
            data: this.getMetrics('margin'),
            fill: false,
            borderWidth: 3,
            borderColor: 'rgb(49, 151, 149)',
            backgroundColor: 'rgb(255, 255, 255)',
            pointRadius: 0,
            pointHitRadius: 20,
            pointHoverRadius: 7,
            pointBackgroundColor: 'rgb(49, 151, 149)',
            borderJoinStyle: 'round',
            yAxisID: 'y3',
            tension: 0.2,
            hidden: true,
          },
        ]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
              borderWidth: 1.5
            },
            type: 'time',
            time: {
              stepSize: this.getStepSize(),
              unit: 'day'
            },
            min: {
              callback: (value) => format(fromDate, { locale: locales[userLocale] })
            },
            max: {
              callback: (value) => format(fromDate, { locale: locales[userLocale] })
            },
            ticks: {
              callback: (value, index, ticks) => format(parse(value, 'MMM dd', new Date()), 'do MMM' , { locale: locales[userLocale] })
            },
            font: { size: 14 },
          },
          y1: {
            title: {
              display: true,
              text: this.currencyValue,
              font: { size: 14 },
            },
            position: 'left',
            display: true,
            grid: {
              borderWidth: 1.5
            }
          },
          y3: {
            min: 0,
            title: {
              display: true,
              text: this.translationMarginValue,
              color: 'rgb(49, 151, 149)',
              padding: { top: 24 },
              font: { size: 14 },
            },
            max: 100,
            position: 'right',
            display: 'auto',
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          }
        },
        datasets: {
          line: {
            showline: false
          }
        },
        interaction: {
          mode: 'nearest',
          intersect: false,
        },
        plugins: {
          tooltip: {
            usePointStyle: true, // taking over the data points style
            enabled: true,
            position: 'nearest',
            callbacks: {
              label: (context) => {
                let label = context.dataset.label
                if (label == '\xA0') {
                  label += triggers[context.dataIndex]['label']
                } else if (label == translationMargin){
                  label += `: ${Math.round(context.parsed.y * 100) / 100}`
                } else {
                  label += `: ${Math.round(context.parsed.y * 100) / 100} ${currency}`
                }

                return label
              },
              title: (context) => format(new Date(context[0].parsed.x), 'do MMMM yyyy', { locale: locales[userLocale] }),
              footer: (context) => {
                let date = format(new Date(context[0].parsed.x), 'yyyy-MM-dd')
                let count = footers[date]
                if (count > 0) {
                  return translationFooters.replace(/PLACEHOLDER|\d+/g, count)
                }
              }
            }
          },
          filler: {
            propagate: true
          },
          legend: {
            labels: {
              usePointStyle: true,
              font: {
                size: 14
              },
              filter: (item, chart) => !item.text.includes('\xA0')
            },
            title: {
              display: true,
              text: '',
              font: {
                size: 18
              },
            },
            position: 'bottom',
            align: 'middle',
            padding: 24,
            onHover: (event, legendItem, legend) => {
              event.native.target.style.cursor = 'pointer'
              legendItem.fontColor = '#000000'
              event.chart.render();
            },
            onLeave: (event, legendItem, legend) => {
              event.native.target.style.cursor = 'default'
              legendItem.fontColor = '#666'
              event.chart.render();
            },
          },
          title: {
            display: true,
            text: basicStatsChart,
            align: 'center',
            color: 'rgb(75, 75, 75)',
            padding: {
              top: 10,
              bottom: 30
            },
            font: {
              size: 20
            }
          },
          autocolors: false,
          annotation: {
            drawTime: 'afterDraw'
          },
        },
        onClick(e) {
          const metadata = chart.getDatasetMeta(0)
          const clickedX = e.x
          let intervalStart
          let intervalEnd
          let uniqueTimestamps = uniq(metadata['_parsed'].map(item => item.x)) // uniquing because there can be multiple triggers in 1 day

          // find the interval between triggers which was clicked inside
          if (metadata.data.length == 0) {
            intervalStart = fromDate
            intervalEnd = toDate
          } else {
            metadata.data.forEach((item, index) => {
              if (clickedX < metadata.data[0].x) {
                intervalStart = fromDate
                intervalEnd = metadata['_parsed'][0].x
              } else if (clickedX > metadata.data[index].x && index < (metadata.data.length - 1) && clickedX < metadata.data[index + 1].x) {
                intervalStart = metadata['_parsed'][index].x
                intervalEnd = metadata['_parsed'][index + 1].x
              } else if (clickedX > metadata.data[metadata.data.length - 1].x) {
                intervalStart = metadata['_parsed'][metadata.data.length - 1].x
                intervalEnd = toDate
              }
            })
          }
          if (format(intervalStart, 'yyyy-MM-dd') == format(intervalEnd, 'yyyy-MM-dd')) {
            intervalStart = new Date(uniqueTimestamps[uniqueTimestamps.length - 2])
          }

          // adjust chart to interval set by triggers
          let clickIntervalStartDate = new Date(intervalStart)
          let clickIntervalEndDate = new Date(intervalEnd)
          chart.update()

          // update query params & date picker and reload page
          const url = new URL(window.location.href)
          url.searchParams.delete('interval')
          url.searchParams.set('from', format(clickIntervalStartDate, 'yyyy-MM-dd'))
          url.searchParams.set('to', format(clickIntervalEndDate, 'yyyy-MM-dd'))
          window.location.assign(url)
        },

        onHover(e) {
          // the duplication is happening here because when the logic is extracted into a separate
          // method, it's failing there and complaining about the method not being a method

          // find the interval between triggers which is being hovered over
          const metadata = chart.getDatasetMeta(0)
          const positionX = e.x
          let intervalStart
          let intervalEnd

          const parsedFromDate = parseISO(format(fromDate, 'yyyy-MM-dd'))
          const parsedToDate = parseISO(format(toDate, 'yyyy-MM-dd'))
          let uniqueTimestamps = uniq(metadata['_parsed'].map(item => item.x)) // uniquing because there can be multiple triggers in 1 day

          if (metadata.data.length == 0) {
            intervalStart = parsedFromDate
            intervalEnd = parsedToDate
          } else {
            metadata.data.forEach((item, index) => {
              if (positionX < metadata.data[0].x) {
                intervalStart = parsedFromDate
                intervalEnd = metadata['_parsed'][0].x
              } else if (positionX > metadata.data[index].x && index < (metadata.data.length - 1) && positionX < metadata.data[index + 1].x) {
                intervalStart = metadata['_parsed'][index].x
                intervalEnd = metadata['_parsed'][index + 1].x
              } else if (positionX > metadata.data[metadata.data.length - 1].x) {
                intervalStart = metadata['_parsed'][metadata.data.length - 1].x
                intervalEnd = parsedToDate
              } else if (positionX >= metadata.controller.chart.chartArea.right) {
                intervalStart = uniqueTimestamps[uniqueTimestamps.length - 2]
                intervalEnd = parsedToDate
              }
            })
          }

          let intervalStartDate = new Date(intervalStart)
          let intervalEndDate = new Date(intervalEnd)
          if (format(intervalStartDate, 'yyyy-MM-dd') == format(intervalEndDate, 'yyyy-MM-dd')) {
            intervalStartDate = new Date(uniqueTimestamps[uniqueTimestamps.length - 2])
          }

          // visually differentiate the hovered interval
          if (format(intervalStartDate, 'yyyy-MM-dd') != format(fromDate, 'yyyy-MM-dd') || format(intervalEndDate, 'yyyy-MM-dd') != format(toDate, 'yyyy-MM-dd')) {
            // this condition prevents displaying annotations when already inside detail view
            e.native.target.style.cursor = 'pointer'
            chart.options.plugins.annotation = {
              animation: {
                duration: 30
              },
              annotations: {
                box: {
                  type: 'box',
                  xMin: intervalStartDate,
                  xMax: intervalEndDate,
                  yMin: 0,
                  yMax: 100,
                  yScaleID: 'y3',
                  backgroundColor: 'rgba(158, 216, 255, 0.1)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  duration: 10
                },
                line: {
                  type: 'line',
                  xMin: intervalStartDate,
                  xMax: intervalEndDate,
                  yMin: 0,
                  yMax: 0,
                  yScaleID: 'y3',
                  borderWidth: 3,
                  borderColor: 'rgb(2, 132, 199)'
                }
              }
            }
            chart.update()
          }

          let chartArea = e.chart.chartArea
          if (e.x < chartArea.left || e.x > chartArea.right || e.y < chartArea.top || e.y > chartArea.bottom) {
            chart.options.plugins.annotation.annotations = {}
            e.native.target.style.cursor = ''
            chart.update()
          }
        },
      }
    })

    // chart.addEventListener('mouseout', e => {
    //   chart.options.plugins.annotation.annotations = {}
    //   chart.update()
    // })
  }

  getTriggers() {
    let triggersLimited = this.triggersByDateValue
    for (let dateTriggers in triggersLimited) {
      triggersLimited[dateTriggers].forEach((trigger, index) => {
        if (index >= this.maxCountValue) {
          triggersLimited[dateTriggers].splice(index)
        }
      })
    }

    let data = []
    this.triggersValue.forEach(trigger => {
      let t = triggersLimited[trigger['created_at']].find(
        element => element['id'] == trigger['id']
      )
      if (t) {
        data.push({ x: format(new Date(trigger['created_at']), 'yyyy-MM-dd'), y: 0 })
      }
    })
    return data
  }

  getMetrics(metric) {
    let data = []
    const metrics = this.statsValue
    const from = this.fromValue
    const to = this.toValue

    for (let d = new Date(from); d <= new Date(to); new Date(d.setDate(d.getDate() + 1))) {
      let date = format(d, 'yyyy-MM-dd')

      if (metrics[date]) {
        let value = metrics[date][metric]

        if (value && metric == 'margin') {
          data.push({ x: date, y: value * 100 })
        } else if (value) {
          data.push({ x: date, y: value})
        }
      } else {
        data.push({ x: date, y: null })
      }
    }

    return data
  }

  duration() {
    return intervalToDuration({
      start: new Date(this.fromValue),
      end: new Date(this.toValue)
    })
  }

  getStepSize() {
    let duration = this.duration()

    if (duration['months'] > 0 || duration['years'] > 0) {
      return 4
    } else if (duration.days > 15 && duration['months'] == 0 && duration['years'] == 0) {
      return 3
    } else if (duration.days < 8 && duration['months'] == 0 && duration['years'] == 0) {
      return 1
    } else {
      return 2
    }
  }
}
