import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "dialog", "confirmDialog", "setLocation" ]
  static values = { show: Boolean }

  connect() {
    this.dialogButtonStatus = false
    if (this.showValue) {
      this.show()
    }
  }

  disconnect() {
    this.removeSpace()
  }

  show() {
    this.dialogTarget.classList.remove('hidden')
    this.addSpace()
  }

  hide() {
    this.dialogTarget.classList.add('hidden')
    this.removeSpace()
  }

  addSpace() {
    this.spaceTarget.classList.add('pt-16')
  }

  removeSpace() {
    if (this.spaceTarget){
      this.spaceTarget.classList.remove('pt-16')
    }
  }

  saveChangesButton() {
    this.dialogButtonStatus = true
    this.removeSpace()
  }

  confirmLeave(event) {
    this.redirectLocation = event.detail.url
    if (!this.dialogTarget.classList.contains('hidden') && !this.dialogButtonStatus) {
      event.preventDefault()
      this.confirmDialogTarget.classList.remove('hidden')
    }
  }

  removeConfirmDialog() {
    this.confirmDialogTarget.classList.add('hidden')
  }

  redirect() {
    this.setLocationTarget.setAttribute('href', this.redirectLocation)
    this.confirmDialogTarget.classList.add('hidden')
    this.saveChangesButton()
  }

  get spaceTarget() {
    return document.querySelector('.save-changes-dialog-space')
  }
}
