import { Controller } from '@hotwired/stimulus'
import { autocomplete } from '@algolia/autocomplete-js'

export default class extends Controller {
  static targets = [ "search", "panel", "container", "item", "template", "added", "selectedField" ]
  static values = { data: Array, placeholder: String, noResultsFound: String, url: String }
  static classes = [ "input", "panel", "item" ]

  connect() {
    this.autocomplete = this.initializeAutocomplete()

    if (this.dataValue.length <= 1) {
      document.getElementById('suggestion-input').classList.add("invisible")
    }
  }

  disconnect() {
    this.autocomplete?.destroy()
  }

  add(event) {
    const valueName = event.target.dataset.valueName
    const valueId = event.target.dataset.valueId

    this.insert(valueName, valueId)
    this.autocomplete.setQuery('')
  }

  insert(valueName, valueId) {
    const data = [...this.containerTarget.getElementsByClassName('item-name')]
    let valueIds = data.map(template => template.id)

    if (!valueIds.includes(valueId)) {
      let valueItem = this.templateTarget.innerHTML.replace(/VALUE_NAME/g, valueName).replace(/VALUE_ID/g, valueId)
      this.containerTarget.insertAdjacentHTML('beforeend', valueItem)
      document.getElementById("change-log-save-button").click()
    }
  }

  checkCorrectItems() {
    this.itemTargets.find(item => item.remove())
  }

  insertCustomValue(element) {
    this.insert(element.value, element.value)
    this.autocomplete.setQuery('')
    setTimeout(function(){
      element.focus();
    }, 100);
  }

  remove(event) {
    const item = this.itemTargets.find(item => item.contains(event.target))
    item.remove()
    document.getElementById("change-log-save-button").click()
  }

  initializeAutocomplete() {
    return autocomplete({
      container: this.searchTarget, // where search field will be rendered
      panelContainer: this.panelTarget, // where autocomplete results will be rendered
      panelPlacement: 'start',
      placeholder: this.placeholderValue,
      openOnFocus: true,
      detachedMediaQuery: 'none', // don't use detached mode
      classNames: {
        input: this.inputClasses.join(' '),
        panel: this.panelClasses.join(' '),
        item: this.itemClasses.join(' '),
        inputWrapperPrefix: 'hidden',
        inputWrapperSuffix: 'hidden',
      },
      onSubmit: ({ event }) => {
        let element = this.searchTarget.getElementsByClassName('aa-Input')[0]
        this.addedTarget.dispatchEvent(new Event('change'))
        this.insertCustomValue(element)
      },
      getSources: () => {
        return [
          {
            sourceId: 'links',
            getItems: ({ query }) => this.searchData(query),
            templates: {
              item: ({ item, createElement }) => this.autocompleteItem(item, createElement),
              noResults: ({ createElement }) => this.noResults(createElement),
            }
          }
        ]
      }
    })
  }

  searchData(query) {
    let data = []
    this.dataValue.forEach(item => {
      if (item.name.includes(query)) {
        data.push({ name: item.name, id: item.id })
      }
    })

    if (query) {
      return data
    } else {
      return this.dataValue
    }
  }

  autocompleteItem(item, createElement) {
    return createElement('button', {
      type: "button",
      "data-action": "change-logs--strategy-values#add",
      "data-value-id": item.id,
      "data-value-name": item.name,
      class: "w-full text-left py-1 px-4",
    }, item.name)
  }

  noResults(createElement) {
    return createElement('span', {
      class: "min-w-max py-1 px-4 no_result text-gray-400"
    }, this.noResultsFoundValue)
  }
}
