import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = [ "anchor", "content" ]
  static values = { placement: String }

  connect() {
    this.popper = null
    useClickOutside(this)
  }

  toggle() {
    this.lazyCreatePopper()
    this.contentTarget.classList.toggle('hidden')
  }

  show() {
    this.lazyCreatePopper()
    this.contentTarget.classList.remove('hidden')
  }

  hide() {
    this.contentTarget.classList.add('hidden')
  }

  clickOutside() {
    this.hide()
  }

  // Creating a popper instance for lots of items (e.g. groups) upfront
  // can add significant performance overhead in rendering. So we lazy-create
  // popper instances on click.
  lazyCreatePopper() {
    if (this.popper) return

    createPopper(this.anchorTarget, this.contentTarget, {
      placement: this.placementValue || "left-start",
    })
  }
}
