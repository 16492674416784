import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["margin", "field", "sign"]

  connect() {
    if (this.hasMarginTarget) {
      this.checkFieldsValues()
    } else {
      this.checkFieldValue()
    }
  }

  checkFieldValue() {
    if (this.fieldTarget.value >= 0 && this.fieldTarget.value != "") {
      this.fieldTarget.classList.add("pl-5")
      this.signTarget.classList.remove("hidden")
    } else {
      this.fieldTarget.classList.remove("pl-5")
      this.signTarget.classList.add("hidden")
    }
  }

  checkFieldsValues() {
    this.signTargets.forEach((element, index) => {
      if (this.fieldTarget.value > 0 && this.fieldTarget.value != "") {
        element.classList.remove("hidden")
        this.fieldTarget.classList.add("pl-5")
      } else {
        this.fieldTarget.classList.remove("pl-5")
        element.classList.add("hidden")
      }
    })
  }
}
