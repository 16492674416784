import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['containerDiv', 'arrow'];

  toggleHidden(event) {
    event.preventDefault();

    const id = event.target.dataset.id;
    const hiddenDiv = this.containerDivTargets.find(div => div.dataset.id === id);

    hiddenDiv.classList.toggle('hidden');
    this.arrowTarget.classList.toggle('rotate-90');
  }
}
