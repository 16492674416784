import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'fromSelect', 'toSelect', 'fromValue', 'toValue' ]

  connect() {
    let fromChild = Array.prototype.slice.call(this.fromSelectTarget.children).filter(child => child.nodeName === "SELECT")[0]
    let toChild = Array.prototype.slice.call(this.toSelectTarget.children).filter(child => child.nodeName === "SELECT")[0]
    this.updateClass(fromChild)
    this.updateClass(toChild)

    if (!this.fromValueTarget.value && !this.toValueTarget.value) {
      this.setDefault(fromChild, '00')
      this.setDefault(toChild, '05')
    }

    this.adjustSelect(fromChild)
    this.adjustSelect(toChild)
  }

  updateClass(element) {
    element.classList.add('btn', 'btn-gray')
  }

  setDefault(element, value) {
    Array.prototype.slice.call(element.options).forEach(option => {
      if (option.selected === 'selected') {
        option.removeAttribute('selected')
      }
      if (option.value === value) {
        option.selected = 'selected'
      }
    })
  }

  adjustSelect(element) {
    this.addMinutesToOptions(element)
    this.addSaveChanges(element)
  }

  addMinutesToOptions(element) {
    Array.prototype.slice.call(element.options).forEach(option => {
      if (option.innerHTML.length == 2) {
        option.insertAdjacentHTML('beforeend', ':00')
      }
    })
  }

  addSaveChanges(element) {
    element.setAttribute('data-action', 'save-changes#show products--sidebar#refresh')
  }
}
