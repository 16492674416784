import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "link", "subtree", "statusContainer", "statusCheckbox" ]
  static values = { selected: String }

  connect() {
    if (this.linkTargets.length) {
      if (this.selectedValue && this.linkTargets.some((element) => element.getAttribute('data-id') == this.selectedValue)) {
        this.linkTargets.forEach(item => {
          if (item.getAttribute('data-id') == this.selectedValue) {
            item.click()
            let parent_id = item.dataset.parent
            this.subtreeTargets.forEach(item => {
              if (item.getAttribute('data-id') == parent_id) {
                item.classList.toggle("hidden")
              }
            })
          }
        })
      } else {
        this.linkTargets[0].click()
      }
    }
  }

  updateStatus(event) {
    const checkbox = event.target
    const productSetId = checkbox.dataset.id
    const parentId = checkbox.dataset.parentId
    const hasSubgroups = checkbox.dataset.hasSubgroups === 'true'
    const isChild = checkbox.dataset.isChild === 'true'

    this.logStatusToContainer(productSetId, checkbox.checked)

    if (hasSubgroups) {
      this.unifyChildren(productSetId, checkbox.checked)
    } else if (isChild) {
      const parent =
        document.querySelector(`input[name="product-set-toggle"][data-id="${parentId}"]`) || // on index page
        document.querySelector(`input[name="product_set[active]"][data-id="${parentId}"]`) // on edit page
      this.unifyParent(parentId, parent, checkbox.checked, parent.checked)
    }
  }

  unifyChildren(parentProductSetId, parentChecked) {
    this.statusCheckboxTargets.forEach(childCheckbox => {
      if (childCheckbox.dataset.parentId === parentProductSetId) { // Is indeed a subgroup of this parent
        if (parentChecked && !childCheckbox.checked) {
          this.logStatusToContainer(childCheckbox.dataset.id, childCheckbox.checked = true)
        } else if (!parentChecked && childCheckbox.checked) {
          this.logStatusToContainer(childCheckbox.dataset.id, childCheckbox.checked = false)
        }
      }
    })
  }

  unifyParent(parentId, parent, childChecked, parentChecked) {
    if (childChecked && !parentChecked) { // parent off but child on - turn on parent
      this.logStatusToContainer(parentId, parent.checked = true)
    } else if (!childChecked && parentChecked && this.childrenAreOff(parentId)) { // parent on but children off - turn off parent
      this.logStatusToContainer(parentId, parent.checked = false)
    }
  }

  childrenAreOff(parentId) {
    let retVal = true
    this.statusCheckboxTargets.every(childCheckbox => {
      if (childCheckbox.dataset.parentId === parentId && childCheckbox.checked) {
        return retVal = false
      }
      return true;
    })
    return retVal
  }

  logStatusToContainer(productSetId, checked) {
    if (this.hasStatusContainerTarget) {
      const inputElementToRemove = document.getElementById(productSetId)

      if (inputElementToRemove) {
        inputElementToRemove.remove()
      }

      let inputElement = document.createElement('input')
      inputElement.type = 'hidden'
      inputElement.value = inputElement.id = productSetId
      inputElement.name = checked ? 'activate[]' : 'deactivate[]'
      this.statusContainerTarget.appendChild(inputElement)
    }
  }
}
