import { Controller } from '@hotwired/stimulus'
import { useDispatch } from 'stimulus-use'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ['content', 'overlay']
  static values = { anchor: String }

  connect() {
    this.anchorElement.classList.add('relative')
    useDispatch(this)
  }

  open(event) {
    if (this.overlayTarget.classList.contains('hidden')) {
      this.dispatch('open')
      this.enterTransition()
    }
  }

  close(event) {
    if (!this.overlayTarget.classList.contains('hidden')) {
      this.dispatch('close')
      this.leaveTransition()
    }
  }

  enterTransition() {
    enter(this.overlayTarget)
    enter(this.contentTarget)
  }

  leaveTransition() {
    leave(this.overlayTarget)
    leave(this.contentTarget)
  }

  get anchorElement() {
    return this.anchorValue ? document.querySelector(this.anchorValue) : this.element
  }
}
