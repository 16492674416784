
export function csrfToken() {
  const metaTag = document.querySelector('meta[name=csrf-token]')
  return metaTag?.content // CSRF token is not available in tests
}

export function round(number) {
  const roundedNumber = Math.round(number * 10) / 10
  return roundedNumber
}
