import { Controller } from '@hotwired/stimulus'
import { visit } from "@hotwired/turbo"

export default class extends Controller {
  static values = { url: String }

  submit(event) {
    this.element.closest('turbo-frame').src = this.element.value
  }
}
