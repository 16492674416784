import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['up', 'down', 'order', 'frame']

  // up -> ascending (bottom to top), down -> descending (top to bottom)
  toggle() {
    if (this.upTarget.classList.contains('hidden')) {
      this.show(this.upTarget)
      this.hide(this.downTarget)
      this.setOrder('asc')
    } else {
      this.hide(this.upTarget)
      this.show(this.downTarget)
      this.setOrder('desc')
    }  
  }

  show(element) {
    element.classList.remove('hidden')
  }

  hide(element) {
    element.classList.add('hidden')
  }

  setOrder(order) {
    let src = new URL(this.frameTarget.src)
    src.searchParams.set('order', order)
    this.frameTarget.setAttribute('src', src)
  }
}
