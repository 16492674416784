import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { master: String }

  connect() {
    this.hide()
  }

  show() {
    this.variantRows.forEach(row => row.classList.remove('hidden'))
  }

  hide() {
    this.variantRows.forEach(row => row.classList.add('hidden'))
  }

  get variantRows() {
    const variantElements = document.querySelectorAll(`[data-variant='${this.masterValue}']`)

    return Array.from(variantElements).map(element => element.closest('tr'))
  }
}
