import '../src/init/turbo'
import '../src/init/stimulus'
import '../src/init/sentry'
import '../src/init/local-time'

require("chartkick").use(require("highcharts"))

import '../src/controllers'
import '../stylesheets/application.css'

require.context('../images', true)
