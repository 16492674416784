import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static targets = [ "form" ]
  static debounces = ['query']

  connect() {
    useDebounce(this, { wait: 500 })
  }

  query() {
    this.submit()
  }

  submit() {
    this.formTarget.requestSubmit()
  }

}
