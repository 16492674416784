import { Controller } from '@hotwired/stimulus';
import ApexCharts from 'apexcharts';

export default class extends Controller {
  static values = { data: Array }

  connect() {

    var options = {
      chart: {
        height: '120px',
        width: '400px',
        type: 'line',
        dropShadow: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      colors: [
        '#00A2F2',
        '#4ABE80'
      ],
      stroke: {
        curve: 'straight', //smooth
        width: 3
      },
      grid: {
        show: false
      },
      legend: {
        show: false
      },
      yaxis: {
        show: false,
        labels: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      xaxis: {
        show: false,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      series: this.dataValue
    }
    var chart = new ApexCharts(this.element, options);

    chart.render();
  }
}
