import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["input"];

  handleChangeTimeout;

  connect() {
    const frame_af = document.getElementById('competitor_prices').dataset.autofocus

    if (frame_af == 'true') {
      this.inputTarget.focus();
    } else {
      this.inputTarget.blur();
    }

    document.getElementById('competitor_prices').setAttribute('data-autofocus', false);
  }

  handleChange() {
    const value = this.element.value;
    const frame = this.element.closest('turbo-frame');
    const url = new URL(frame.src);

    frame.setAttribute('data-autofocus', true);
    clearTimeout(this.handleChangeTimeout);

    this.handleChangeTimeout = setTimeout(() => {
      url.searchParams.set("query", value);
      frame.src = url.toString();
    }, 500);
  }

  focus(event) {
    const input = event.target;
    const length = input.value.length;

    input.setSelectionRange(length, length);
  }
}
