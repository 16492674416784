import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'categories', 'manufacturers', 'categoriesRadioButton', 'manufacturersRadioButton', 'productsPerGroup', 'number', 'minimumProductsCheckbox', 'minimumProductsLabel', 'tooltip', 'startButton', 'submitButton', 'submitSpinner', 'startSpinner' ]
  static values = { categories: Object, manufacturers: Object }

  connect() {
    this.enableStartButton()
  }

  checkCategories() {
    this.countItems(this.categoriesValue)
    this.enableMinimumProducts()
    this.enableSubmit()
    this.categoriesTarget.classList.add('active')
    this.manufacturersTarget.classList.remove('active')
    this.categoriesTarget.firstElementChild.classList.add('bg-blue-100')
    this.manufacturersTarget.firstElementChild.classList.remove('bg-blue-100')

  }

  checkManufacturers() {
    this.countItems(this.manufacturersValue)
    this.enableMinimumProducts()
    this.enableSubmit()
    this.manufacturersTarget.classList.add('active')
    this.categoriesTarget.classList.remove('active')
    this.manufacturersTarget.firstElementChild.classList.add('bg-blue-100')
    this.categoriesTarget.firstElementChild.classList.remove('bg-blue-100')
  }

  countItems(items) {
    const productsPerGroup = this.productsPerGroupTarget.value
    const counts = Object.values(items)
    const groupsCount = counts.filter(item => item >= productsPerGroup).length
    this.updateNumber(groupsCount)
  }

  recount() {
    if (this.categoriesRadioButtonTarget.checked === true) {
      this.countItems(this.categoriesValue)
    } else if (this.manufacturersRadioButtonTarget.checked === true) {
      this.countItems(this.manufacturersValue)
    }
  }

  updateNumber(number) {
    const newValue = this.numberTarget.innerHTML.replace(/NUMBER|\d+/g, number)
    this.numberTarget.innerHTML = newValue
    this.numberTarget.classList.remove('hidden')
  }

  disableEnter(e) {
    if (e.keyCode == 13) {
      e.preventDefault()
    }
  }

  disableStartButton() {
    this.startButtonTarget.classList.add('btn-disabled')
    this.startSpinnerTarget.classList.remove('hidden')
  }

  enableStartButton() {
    if (this.hasStartButtonTarget) {
      this.startButtonTarget.classList.remove('btn-disabled')
      this.startSpinnerTarget.classList.add('hidden')
    }
  }

  disableSubmit() {
    this.submitButtonTarget.classList.add('btn-disabled')
    this.submitSpinnerTarget.classList.remove('hidden')
  }

  enableSubmit() {
    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.classList.add('btn-blue')
    this.submitButtonTarget.classList.remove('btn-disabled')
  }

  enableMinimumProducts() {
    this.minimumProductsCheckboxTarget.disabled = false
    this.tooltipTarget.classList.add('hidden')
    this.minimumProductsLabelTarget.classList.remove('text-gray-400')
    this.minimumProductsLabelTarget.classList.add('text-gray-700')
  }
}
