import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['instanceForm', 'demoForm', 'instanceButton', 'demoButton', 'field', 'typeField', 'currencyField', 'feedUrlField']
  static values = { type: String }

  connect(){
    if (this.typeValue == 'demo'){
      this.instanceButtonTarget.classList.remove('bg-white')
      this.instanceButtonTarget.classList.remove('rounded-md')
      this.demoButtonTarget.classList.add('bg-white')
      this.demoButtonTarget.classList.add('rounded-md')
      this.demoFormTarget.classList.remove('hidden')
    }
    else {
      this.instanceButtonTarget.classList.add('bg-white')
      this.instanceButtonTarget.classList.add('rounded-md')
      this.demoButtonTarget.classList.remove('bg-white')
      this.demoButtonTarget.classList.remove('rounded-md')
      this.demoFormTarget.classList.add('hidden')
    }
  }
  
  toggle(){
    this.instanceButtonTarget.classList.toggle('bg-white')
    this.instanceButtonTarget.classList.toggle('rounded-md')
    this.demoButtonTarget.classList.toggle('bg-white')
    this.demoButtonTarget.classList.toggle('rounded-md')
    this.demoFormTarget.classList.toggle('hidden')

    if (this.fieldTarget.value === 'instance') {
      this.fieldTarget.value = "demo";
    } else {
        this.fieldTarget.value = "instance";
    }
  }

  validation(event) {
    if (this.fieldTarget.value === 'demo') {

      let isTypeEmpty = this.typeFieldTarget.value === '';
      let isCurrencyEmpty = this.currencyFieldTarget.value === '';
      let isFeedUrlEmpty = this.feedUrlFieldTarget.value === '';

      if (isTypeEmpty || isCurrencyEmpty || isFeedUrlEmpty) {
        event.preventDefault();

        if (isTypeEmpty) {
          this.typeFieldTarget.classList.add("border-4", "border-red-600");
        }
        if (isCurrencyEmpty) {
          this.currencyFieldTarget.classList.add("border-4", "border-red-600");
        }
      }
    }
  }
}
