import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const context = require.context("./controllers", true, /_controller\.js$/)
const contextComponents = require.context("../../components", true, /_controller\.js$/)

Stimulus.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
)
