import { Controller } from '@hotwired/stimulus'

/**
 * Works with Stimulus Reveal Controller Component as a middle-man, if more complex behaviour is needed and component
 * itself is not enough.
 *
 * Component:
 *  node_modules/stimulus-reveal-controller/dist/stimulus-reveal-controller.es.js
 *
 * https://www.stimulus-components.com/docs/stimulus-reveal-controller/
 */
export default class extends Controller {
  static targets = [ "revealIcon" ]

  toggleIfNotRevealed() {
    if (this.isTargetVisible(this.revealIconTarget)) {
      this.dispatch("toggle")
    }
  }

  isTargetVisible(targetElement) {
    return targetElement && targetElement.offsetParent !== null;
  }
}
