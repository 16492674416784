import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["threshold"]
  static values = { threshold: String }


  updateValue(event) {
    this.thresholdTarget.value = event.target.value ? parseFloat(event.target.value) / 100 : null
    this.thresholdValue = event.target.value ? parseFloat(event.target.value) / 100 : null
  }

}
