import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["input", "show", "hide"]

  show() {
    this.inputTarget.type = "text"
    this.hideTarget.classList.remove('hidden')
    this.showTarget.classList.add('hidden')
  }

  hide() {
    this.inputTarget.type = "password"
    this.showTarget.classList.remove('hidden')
    this.hideTarget.classList.add('hidden')
  }
}
