import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'
import { csrfToken } from '../../utils.js'

export default class extends Controller {
  static targets = [ "form", "frame" ]
  static values = { delay: Number, filters: Array }
  static debounces = ['query']

  connect() {
    useDebounce(this, { wait: this.delayValue || 500 })

    let url = new URL(window.location)
    let params = new URLSearchParams(url.search)

    // POST submission of form is needed (during loading filters, GET method is sent from action)
    if (params.has('filters_loaded') && params.get('filters_loaded') === 'true') {
      this.submit()
    }
  }

  query() {
    this.submit()
  }

  submit() {
    this.formTarget.requestSubmit()
  }

  interceptRequest(e) {
    e.preventDefault()

    this.updateFetchOptions(e.detail.fetchOptions)

    e.detail.resume()
  }

  async clearParams() {
    await new Promise(resolve => setImmediate(resolve));
    let url = new URL(window.location)
    let filters = this.filtersValue

    for (let param of Array.from(url.searchParams)) {
      let fieldName = param[0].split('_value')[0].split('_operator')[0]

      if (filters.includes(fieldName) && !url.searchParams.has(fieldName)) {
        url.searchParams.delete(fieldName.concat('_value'))
        url.searchParams.delete(fieldName.concat('_operator'))
      }
    }

    history.replaceState({}, '', url.toString());
  }

  updateFetchOptions(fetchOptions) {
    let formData = new FormData(this.formTarget)

    fetchOptions.method = 'POST'
    formData.set('authenticity_token', csrfToken())

    fetchOptions.body = formData
  }
}
