import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["active", "disabled", "deleted", "all", "input"]

  connect(){
    this.switch()
  }

  switch(){
    const url = new URL(window.location.href)
    const status = url.searchParams.get('status')
    const q = url.searchParams.get('q')
    this.inputTarget.value = q

    if(status === 'disabled'){
      this.switchDisabled()
    }
    else if(status === 'to_be_deleted'){
      this.switchDeleted()
    }
    else if(status === 'all'){
      this.switchAll()
    }
    else {
      this.switchActive()
    }
  }

  switchClass(target, add) {
    const classesToRemove = ["bg-gray-600", "text-white"];
    const classesToAdd = add ? ["bg-gray-600", "text-white"] : ["bg-gray-100", "text-black"];


    this[target].classList.remove(...classesToRemove);
    this[target].classList.add(...classesToAdd);
  }

  switchActive() {
    this.switchClass('disabledTarget', false);
    this.switchClass('deletedTarget', false);
    this.switchClass('allTarget', false);
    this.switchClass('activeTarget', true);
  }

  switchDisabled() {
    this.switchClass('activeTarget', false);
    this.switchClass('deletedTarget', false);
    this.switchClass('allTarget', false);
    this.switchClass('disabledTarget', true);
    
  }

  switchDeleted() {
    this.switchClass('activeTarget', false);
    this.switchClass('disabledTarget', false);
    this.switchClass('allTarget', false);
    this.switchClass('deletedTarget', true);
  }

  switchAll() {
    this.switchClass('activeTarget', false);
    this.switchClass('disabledTarget', false);
    this.switchClass('deletedTarget', false);
    this.switchClass('allTarget', true);
  }
}
