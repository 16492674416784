import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = [ 'dimension', 'purchasePrice', 'originalPrice', 'marketingPrice' ]
  static values = { url: String }

  handleChange() {
    this.retrievePrices()
  }

  async retrievePrices() {
    if (this.dimensionTarget.value === '') return

    const response = await post(this.urlValue, {
      body: {
        dimension: this.dimensionValue,
        dimension_value: this.dimensionTarget.value,
      },
      contentType: 'application/json'
    })

    const result = await response.json
    let price = result.price
    this.purchasePriceTarget.value = result.purchase_price
    this.originalPriceTarget.value = price
    this.marketingPriceTarget.value = price
  }

  get dimensionValue() {
    const dimensionField = document.getElementById('marketing_dimension')
    return dimensionField.value
  }
}
