import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  link(event) {
    if (this.element.classList.contains('disabled')) {
      event.preventDefault();
    }
    this.element.classList.add('disabled')
  }
}
