import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "type", "field", "value", "margin", "valueType", "competitor", "delivery", "fixedPrice", "basePrice", 'competitorStock' ]
  static values = { "delivery": Boolean }

  connect() {
    this.updateVisibility()
    this.updateMargin()
  }

  updateMargin() {
    this.marginTarget.value = this.calculateMargin(this.value)
  }

  updateValue() {
    this.valueTarget.value = this.calculateValue(this.margin)
  }

  updateVisibility() {
    this.fieldNames.forEach(fieldName => {
      const wrapper = this.[`${fieldName}Target`].parentElement

      if (this.fieldVisible(fieldName)) wrapper.classList.remove('hidden')
      else                              wrapper.classList.add('hidden')
    })
  }

  basePriceChanged(event) {
    if (this.types.includes(event.target.value)) {
      this.typeTarget.value = event.target.value
    } else {
      this.typeTarget.value = 'field'
      this.fieldTarget.value = event.target.value
    }
    if (!this.typeTarget.value.includes('competitor')) {
      this.competitorStockTarget.value = 0
    }

    this.updateVisibility()
  }

  fieldVisible(name) {
    return {
      'type': true,
      'field': this.typeTarget.value === 'field',
      'value': this.typeTarget.value !== 'fixed_price',
      'valueType': this.typeTarget.value !== 'fixed_price',
      'margin': this.typeTarget.value === 'field' && this.fieldTarget.value === 'float_PURCHASEPRICE_VAT' && this.valueTypeTarget.value === 'pct',
      'competitor': this.typeTarget.value === 'competitor',
      'delivery': this.typeTarget.value.includes('competitor') && this.deliveryValue,
      'fixedPrice': this.typeTarget.value === 'fixed_price',
      'competitorStock': this.typeTarget.value.includes('competitor')
    }[name]
  }

  calculateMargin(value) {
    let margin = value / ((value + 100) / 100)

    return parseFloat(margin.toFixed(2))
  }

  calculateValue(margin) {
    const absMargin = Math.abs(this.float(margin))

    let value = 0
    if (margin == absMargin) {
      value = margin / ((Math.abs(margin - 100)) / 100)
    } else {
      value = absMargin / ((margin - 100) / 100)
    }

    return parseFloat(value.toFixed(2))
  }

  float(value) {
    return (value !== "") ? parseFloat(value) : 0
  }

  get fieldNames() {
    return ["type", "field", "value", "margin", "valueType", "competitor", "delivery", "fixedPrice", 'competitorStock']
  }

  get value() {
    return this.float(this.valueTarget.value)
  }

  get margin() {
    return this.float(this.marginTarget.value)
  }

  get types() {
    return ['competitor', 'cheapest_competitor', 'most_expensive_competitor', 'fixed_price']
  }
}
