import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'content']
  static classes = ['active', 'inactive']
  static values = { showTabIndex: Number }

  connect() {
    this.select(this.buttonTargets[this.showTabIndexValue || 0])
  }

  switch(event) {
    const selectedButton = this.buttonTargets.find(button => button.contains(event.target))

    this.select(selectedButton)
  }

  select(button) {
    this.selectButton(button)
    this.selectContent(button)
  }

  selectButton(button) {
    this.buttonTargets.forEach(element => {
      if (element === button) {
        element.classList.remove(...this.inactiveClasses)
        element.classList.add(...this.activeClasses)
      } else {
        element.classList.remove(...this.activeClasses)
        element.classList.add(...this.inactiveClasses)
      }
    })
  }

  selectContent(button) {
    this.contentTargets.forEach(element => {
      if (element.dataset.id == button.dataset.id) {
        element.classList.remove('hidden')
      } else {
        element.classList.add('hidden')
      }
    })
  }
}
