import { Controller } from '@hotwired/stimulus'
import { nanoid } from 'nanoid'

export default class extends Controller {
  static targets = [ 'dataStorage' ]

  connect() {
    let storageTargetId = this.dataStorageTarget.id
    let inputs = Array.from(this.dataStorageTarget.getElementsByTagName('input'))
    let hiddenFields = Array.from(document.getElementsByName('marketing[data_storages_attributes][PLACEHOLDER][id]'))
    let idField = hiddenFields.find(field => field.value == storageTargetId.split('_')[2])
    const id = nanoid()
    
    for (let input of inputs) {
      input.name = input.name.replace('PLACEHOLDER', id)
      input.id = input.id.replace('PLACEHOLDER', id)
    }

    if (storageTargetId !== 'new_data_storage' || idField) {
      idField.id = idField.id.replace('PLACEHOLDER', id)
      idField.name = idField.name.replace('PLACEHOLDER', id)
    }
  }
}
