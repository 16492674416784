import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button']

  showButton() {
    this.buttonTarget.classList.remove('invisible')
  }

}
