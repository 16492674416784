import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { accountId: String, propertyId: String }

  fillGa() {
    document.getElementById('data_source_source_options[ga4_account_id]').value = this.accountIdValue
    document.getElementById('data_source_source_options[ga4_property_id]').value = this.propertyIdValue
  }
}
