import { Controller } from '@hotwired/stimulus'
import { navigator } from "@hotwired/turbo"
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static targets = ['frame', 'field']
  static values = { delay: Number }
  static debounces = ['apply']

  connect() {
    useDebounce(this, { wait: this.delayValue })
  }

  apply() {
    this.updateFrame()
    this.updateUrl()
  }

  updateFrame() {
    const src = new URL(this.frameTarget.src)
    src.searchParams.set('q', this.fieldTarget.value)
    this.frameTarget.src = src
    this.frameTarget.reload()
  }

  updateUrl() {
    const url = new URL(window.location.href)
    url.searchParams.set('q', this.fieldTarget.value)
    navigator.history.push(url)
  }
}
