import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['type', 'basePrice', 'valueType', 'margin', 'value']

  connect() {
    this.basePriceChanged()
    this.updateMargin()
  }

  basePriceChanged() {
    if (this.basePriceTarget.value == 'float_PURCHASEPRICE_VAT' && this.valueTypeTarget.value == 'pct') {
      this.marginTarget.parentElement.classList.remove('hidden')
    } else {
      this.marginTarget.parentElement.classList.add('hidden')
    }
  }

  updateMargin() {
    this.marginTarget.value = this.calculateMargin(this.valueTarget.value)
  }

  updateValue() {
    if (this.marginTarget.value > 99) {
      this.marginTarget.value = 99
    } else {
      this.valueTarget.value = this.calculateValue(this.marginTarget.value)
    }
  }

  calculateMargin(value) {
    let margin = this.float(value) / ((this.float(value) + 100) / 100)

    return margin.toFixed(2)
  }

  calculateValue(margin) {
    const absMargin = Math.abs(this.float(margin))

    let value = 0
    if (margin == absMargin) {
      value = margin / ((Math.abs(margin - 100)) / 100)
    } else {
      value = absMargin / ((margin - 100) / 100)
    }

    return parseFloat(value.toFixed(2))
  }

  float(value) {
    return (value !== "") ? parseFloat(value) : 0
  }

  checkFieldValue() {
    if (this.fieldTarget.value >= 0 && this.fieldTarget.value != "") {
      this.fieldTarget.classList.add("pl-5")
      this.signTarget.classList.remove("hidden")
    } else {
      this.fieldTarget.classList.remove("pl-5")
      this.signTarget.classList.add("hidden")
    }
  }
}
