import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = [ 'dimension', 'priorPrice', 'calculated', 'manual', 'loading' ]
  static values = { url: String }

  handleChange() {
    if (this.priorPriceTarget.value === '') {
      this.retrieveCalculated()
    } else {
      this.priorPriceTarget.placeholder = ''
    }
  }

  async retrieveCalculated() {
    if (this.dimensionTarget.value === '') return

    this.showLoadingIcon()
    const response = await post(this.urlValue, {
      body: {
        dimension: this.dimensionValue,
        dimension_value: this.dimensionTarget.value,
      },
      contentType: 'application/json'
    })

    const result = await response.json

    this.loadingTarget.classList.add('hidden')

    if (result.prior_price) {
      // set in placeholder so that it doesn't get submitted
      this.priorPriceTarget.placeholder = result.prior_price.toFixed(1).replace('.', ',')
    } else {
      this.priorPriceTarget.placeholder = ''
    }

    if (result.prior_price && (Math.round(result.prior_price * 100)/100 == Math.round(this.priorPriceTarget.value * 100)/100) || (result.prior_price && !this.priorPriceTarget.value)) { // the OR condition is for manually added lines
      this.showCalculatedIcon()
    } else if (!result.prior_price && !this.priorPriceTarget.value) {
      this.manualTarget.classList.add('hidden')
      this.calculatedTarget.classList.add('hidden')
    } else if (this.priorPriceTarget.value !== '') {
      this.showManualIcon()
    }
  }

  get dimensionValue() {
    const dimensionField = document.getElementById('marketing_dimension')
    return dimensionField.value
  }

  showManualIcon() {
    this.manualTarget.classList.remove('hidden')
    this.priorPriceTarget.placeholder = ''
    this.calculatedTarget.classList.add('hidden')
    this.loadingTarget.classList.add('hidden')
  }

  showCalculatedIcon() {
    this.manualTarget.classList.add('hidden')
    this.loadingTarget.classList.add('hidden')
    this.calculatedTarget.classList.remove('hidden')
  }

  showLoadingIcon() {
    this.manualTarget.classList.add('hidden')
    this.calculatedTarget.classList.add('hidden')
    this.loadingTarget.classList.remove('hidden')
  }
}
