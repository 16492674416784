import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'button' ]

  update(event) {
    const productsUrl = new URL(event.detail.url.href)
    const downloadUrl = new URL(this.buttonTarget.href)

    downloadUrl.search = productsUrl.search

    this.buttonTarget.setAttribute('href', downloadUrl)
  }
}
