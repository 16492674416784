import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [
      'hiddenField', 
      'menu', 
      'dropdown', 
      'redDot', 
      'filterBadge', 
      'filterBadgeTranslation', 
      'allCheckIcon', 
      'successCheckIcon', 
      'failedCheckIcon', 
      'unrepricedCheckIcon', 
      'defaultCheckIcon',
      'resetButton'
    ];  
    
    static values = { 
    success: String,
    failed: String,
    unrepriced: String,
    default: String
  }
  connect(){
    let filter = this.hiddenFieldTarget.value

    this.ShowRedDotInfo(filter)
    this.filterBadge(filter)
    this.setCheckIcon(filter)
  }

  setFilter(event){
    this.hiddenFieldTarget.value = event.target.id
    this.dropdownTarget.setAttribute('data-dropdown-open-value', 'false')
    this.dropdownTarget.setAttribute('aria-expanded', 'false')
    this.menuTarget.classList.add('hidden')

    this.filterBadge(event.target.id)
    this.ShowRedDotInfo(event.target.id)
    this.setCheckIcon(event.target.id)
  }

  ShowRedDotInfo(filter){
    if (filter != 'all'){
      this.redDotTarget.classList.remove('hidden')
    } else {
      this.redDotTarget.classList.add('hidden')
    }
  }
  setCheckIcon(filter) {
    const filters = ['all', 'success', 'failed', 'unrepriced', 'default'];
    
    filters.forEach((type) => {
      const iconTarget = this[`${type}CheckIconTarget`];
      iconTarget.classList.toggle('hidden', filter !== type);
    });
  }

  filterBadge(filter){
    const filterTexts = {
      success: this.successValue,
      failed: this.failedValue,
      unrepriced: this.unrepricedValue,
      default: this.defaultValue
    };

    if (filter !== 'all') {
      this.filterBadgeTranslationTarget.innerText = filterTexts[filter];
      this.filterBadgeTarget.classList.remove("hidden");
      this.resetButtonTarget.id = filter
    } else {
      this.filterBadgeTranslationTarget.innerText = '';
      this.filterBadgeTarget.classList.add("hidden");
      this.resetButtonTarget.id = ''
    }
  }

  removeFilterBadge(event){
    const iconTarget = this[`${event.target.id}CheckIconTarget`];

    this.hiddenFieldTarget.value = 'all'
    this.redDotTarget.classList.add('hidden')
    this.filterBadgeTranslationTarget.innerText = '';
    this.filterBadgeTarget.classList.add("hidden");
    iconTarget.classList.add('hidden')
    this.allCheckIconTarget.classList.remove('hidden')
  }
}
