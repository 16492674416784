import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  // the sortable.js library automatically dispatches the "sort" event
  connect() {
    if (window.innerWidth > 768) {
      this.sortable = new Sortable(this.element, {
        draggable: '[data-sortable-target=draggable]',
        animation: 150
      })
    }
  }
}
