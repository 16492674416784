import { Controller } from '@hotwired/stimulus';
import ApexCharts from 'apexcharts';
import moment from 'moment'

export default class extends Controller {
  static targets = ["chart", "chartData"]

  connect() {

    var options = {
      chart: {
        height: '400px',
        type: 'area',
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        fontFamily: 'Poppins, sans-serif'
      },
      colors: [
        '#3182ce',
        '#319795',
        '#805ad5',
        '#5a67d8',
        '#d53f8c',
        '#38a169',
        '#d69e2e',
        '#dd6b20',
        '#e53e3e',
        '#718096'
      ],
      fill: {
        type: 'gradient',
        gradient: {
          // shade: 'dark',
          type: 'vertical',
          shadeIntensity: 1,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: false,
          opacityFrom: 0.4,
          opacityTo: 0.7,
          stops: [0, 90, 100],
          colorStops: []
        }
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0
      },
      responsive: [{
        breakpoint: undefined,
        options: {}
      }],
      markers: {
        size: 0,
        colors: undefined,
        strokeColors: '#fff',
        strokeWidth: 8,
        strokeOpacity: 0.5,
        fillOpacity: 1,
        discrete: [],
        shape: 'circle',
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        hover: {
          size: 6,
          sizeOffset: 2
        }
      },
      legend: {
        fontFamily: 'Poppins, sans-serif'
      },
      yaxis: {
        tickAmount: 3,
        labels: {
          show: true,
          formatter: function (value) {
            var formatted_value = value == null ? value : value.toFixed(2)
            return Intl.NumberFormat('cs-CZ').format(formatted_value)
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        x: {
          format: 'dd. MMM yyyy'
        }
      },
      xaxis: {
        type: 'datetime'
        // min: new Date(data.from).getTime(),
        // max: new Date(data.to).getTime()
      },
      series: JSON.parse(this.chartDataTarget.value)
    }

    var chart = new ApexCharts(this.chartTarget, options);

    chart.render();
  }
}
