import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { resolved: Boolean }
  static targets = [ "groupBox", "form" ]

  count(event) {
    var checked_items_count = this.groupBoxTargets.filter(checkbox => checkbox.checked).length
    var direction = this.resolvedValue == true ? 1 : -1
    var counter = document.getElementById('alerts_counter')
    counter.innerText = parseInt(counter.innerText) + (checked_items_count * direction)
  }

  beforeSubmit(event) {
    if (this.filterSetTo('marketing_end', 'export_failed')) {
      this.resetPricingSpecialist()
    }
  }

  filterSetTo(...inc) {
    return [...inc].indexOf(this.formTarget.elements.filter.value) >= 0
  }

  resetPricingSpecialist() {
    if (this.formTarget.elements.pricing_specialist_id) {
      this.formTarget.elements.pricing_specialist_id.value = null
    }
  }
}
