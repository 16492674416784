import { Controller } from '@hotwired/stimulus'
import { autocomplete } from '@algolia/autocomplete-js'

export default class extends Controller {
  static targets = [ "search", "panel", "container", "item", "template", "added" ]
  static values = { data: Array, placeholder: String, noResultsFound: String }
  static classes = [ "input", "panel", "item" ]

  connect() {
    this.autocomplete = this.initializeAutocomplete()
  }

  disconnect() {
    this.autocomplete.destroy()
  }

  add(event) {
    const groupId = event.target.dataset.groupId
    const groupName = event.target.dataset.groupName

    this.insert(groupId, groupName)

    this.dataValue = this.dataValue.filter(group => group.id != groupId)
    this.autocomplete.setQuery('')
  }

  insert(groupId, groupName) {
    const groupHTML = this.templateTarget.innerHTML
      .replace(/GROUP_NAME/g, groupName)
      .replace(/GROUP_ID/g, groupId)

    this.addedTarget.classList.remove("hidden")
    this.containerTarget.insertAdjacentHTML('afterbegin', groupHTML)
  }

  remove(event) {
    const item = this.itemTargets.find(item => item.contains(event.target))
    item.querySelector('[name*="[_destroy]"]').value = "true"
    item.parentElement.classList.add('hidden')
  }

  initializeAutocomplete() {
    return autocomplete({
      container: this.searchTarget, // where search field will be rendered
      panelContainer: this.panelTarget, // where autocomplete results will be rendered
      panelPlacement: 'start',
      placeholder: this.placeholderValue,
      openOnFocus: true,
      detachedMediaQuery: 'none', // don't use detached mode
      classNames: {
        input: this.inputClasses.join(' '),
        panel: this.panelClasses.join(' '),
        item: this.itemClasses.join(' '),
        inputWrapperPrefix: 'hidden',
        inputWrapperSuffix: 'hidden',
      },
      getSources: () => {
        return [
          {
            sourceId: 'links',
            getItems: ({ query }) => this.searchData(query),
            templates: {
              item: ({ item, createElement }) => this.autocompleteItem(item, createElement),
              noResults: ({ createElement }) => this.noResults(createElement),
            }
          }
        ]
      }
    })
  }

  searchData(query) {
    return this.dataValue.filter(({ name }) => {
      return name.toLowerCase().includes(query.toLowerCase())
    })
  }

  autocompleteItem(item, createElement) {
    return createElement('button', {
      type: "button",
      "data-action": "users--groups#add",
      "data-group-id": item.id,
      "data-group-name": item.name,
      class: "w-full text-left py-1 px-4",
    }, item.name)
  }

  noResults(createElement) {
    return createElement('span', {
      class: "min-w-max py-1 px-4 no_result text-gray-400"
    }, this.noResultsFoundValue)
  }
}
