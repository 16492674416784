import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggle', 'activeUntil', 'modelLength']

  // Click using JS to keep button animation well working
  submitToggle() {
    this.toggleTarget.click()
  }

  SyncDurationFields(e) {
    const todayDate = new Date()
    let futureDate

    if(e.target.value) {
      switch (e.target) {
        case this.activeUntilTarget:
          futureDate = new Date(this.activeUntilTarget.value)
          const diffInMillis = futureDate - todayDate

          this.modelLengthTarget.value = Math.ceil(diffInMillis / (1000 * 60 * 60 * 24))
          break

        case this.modelLengthTarget:
          const daysCount = parseInt(this.modelLengthTarget.value)
          futureDate = new Date()
          futureDate.setDate(todayDate.getDate() + daysCount)

          this.activeUntilTarget.value = futureDate.toISOString().split('T')[0]
          break
      }
    }
  }
}
