import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"

if (process.env.sentry_dsn && process.env.sentry_environment) {
  Sentry.init({
    dsn: process.env.sentry_dsn,
    environment: process.env.sentry_environment,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,

    denyUrls: [
      // From this domain is coming error from our knowledge base:
      // TypeError e.dispatchToWidget
      // Cannot read properties of null (reading 'postMessage')
      /widget\.cluster\.groovehq\.com/
    ]
  })
}
