import { Controller } from '@hotwired/stimulus'
import { navigator } from "@hotwired/turbo"
import { csrfToken } from '../../utils.js'
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static targets = ["form", "frame"]
  static values = { refreshDelay: Number }
  static debounces = ["refresh"]

  connect() {
    useDebounce(this, { wait: this.refreshDelayValue })

    // without initially disabling turbo frames, fetching starts before the
    // event listener is registered, so we cannot intercept the request
    this.frameTargets.forEach(frame => frame.removeAttribute('disabled'))
  }

  interceptRequest(event) {
    event.preventDefault() // pause the request

    this.updateFetchOptions(event.detail.fetchOptions)

    // it's a bit weird that this POST request will have both query parameters
    // and form parameters, but it works
    event.detail.resume()
  }

  refresh() {
    if (!this.formTarget.checkValidity()) {
      this.formTarget.reportValidity()
    } else{
      this.frameTargets.forEach(frame => frame.reload())
    }
  }

  updateFetchOptions(fetchOptions) {
    // convert the request to POST to allow us to send item params in the body
    fetchOptions.method = 'POST'
    fetchOptions.body = this.formData()
  }

  formData() {
    const formData = new FormData(this.formTarget)

    // the CSRF token in the form doesn't work for this request, so we grab
    // the global one
    formData.set('authenticity_token', csrfToken())
    formData.delete('_method') // we want the request to be POST

    return formData
  }

  updateUrl(event) {
    const newUrl = new URL(window.location.href)
    const frameUrl = new URL(event.detail.url)

    // we don't want to add item ID to current URL's query parameters
    frameUrl.searchParams.delete('id')

    // copy the query parameters, allowing users to link to products sidebar
    if (newUrl.search != frameUrl.search) {
      newUrl.search = frameUrl.search

      navigator.history.push(newUrl)
    }
  }
}
