import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "item", "link" ]
  static classes = [ "active", "inactive" ]
  static values = { autoselect: Boolean }

  connect() {
    // select the first one by default if ID not passed in the query
    const url = new URL(window.location.href)
    const id = url.searchParams.get('id')
    if (id) {
      this.linkTargets.forEach(link => {
        if (link.id == id) {
          link.click()
        }
      })
    } else if (this.linkTargets.length && this.autoselectValue) {
      this.linkTargets[0].click()
    }
  }

  select(event) {
    this.itemTargets.forEach(item => {
      if (item.contains(event.target)) {
        this.makeActive(item)
      } else {
        this.makeInactive(item)
      }
    })
  }

  scroll() {
    document.getElementsByTagName('main')[0].scrollTo({top: 0, behavior: 'smooth'})
  }

  makeActive(element) {
    element.classList.remove(...this.inactiveClasses)
    element.classList.add(...this.activeClasses)
  }

  makeInactive(element) {
    element.classList.remove(...this.activeClasses)
    element.classList.add(...this.inactiveClasses)
  }
}
