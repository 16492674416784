import { Controller } from '@hotwired/stimulus'

import ApexCharts from 'apexcharts'
import moment from 'moment'

const DEFAULT_HISTORY_CHART_OPTIONS = {
  chart: {
    height: 400,
    type: 'line',
    stacked: false,
    zoom: {
      enabled: false
    },
    toolbar: {
      show: false,
      autoSelected: 'zoom'
    }
  },
  colors: ['#38a169', '#3182ce', '#ed8936', '#e53e3e'],

  dataLabels: {
    enabled: false
  },
  stroke: {
    width: [1, 1, 3, 3]
  },
  xaxis: {
    type: 'category',
    categories: [],
    labels: {
      rotate: -45,
      rotateAlways: true
    }
    /* labels: {
      formatter: function(timestamp) {
        return window.moment(new Date(timestamp)).format('DD. MMM YYYY')
      }
    } */
  },
  yaxis: [
    {
      axisTicks: {
        show: true
      },
      axisBorder: {
        show: true,
        color: '#38a169'
      },
      labels: {
        formatter: function(val) {
          // return Intl.NumberFormat('cs-CZ').format(formatted_value)
          return val ? Intl.NumberFormat('cs-CZ').format(val.toFixed(1)) : undefined
        },
        style: {
          colors: '#38a169'
        }
      },
      title: {
        text: 'Počet transakcí',
        translationKey: 'transaction_count',
        style: {
          color: '#38a169'
        }
      }
    },
    {
      opposite: false,
      axisTicks: {
        show: true
      },
      axisBorder: {
        show: true,
        color: '#3182ce'
      },
      labels: {
        formatter: function(val) {
          return val ? val.toFixed(1) : undefined
        },
        style: {
          colors: '#3182ce'
        }
      },
      title: {
        text: 'Množství prodaných produktů',
        translationKey: 'sold_count',
        style: {
          color: '#3182ce'
        }
      }
    },
    {
      opposite: true,
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: true,
        color: '#ed8936'
      },
      labels: {
        formatter: function(val) {
          return val ? val.toFixed(1) : undefined
        },
        style: {
          colors: '#ed8936'
        }
      },
      title: {
        text: 'Obrat',
        translationKey: 'revenue',
        style: {
          color: '#ed8936'
        }
      }
    },
    {
      opposite: true,
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: true,
        color: '#e53e3e'
      },
      labels: {
        formatter: function(val) {
          return val ? val.toFixed(1) : undefined
        },
        style: {
          colors: '#e53e3e'
        }
      },
      title: {
        text: 'Počet zhlédnutí',
        translationKey: 'display_count',
        style: {
          color: '#e53e3e'
        }
      }
    }
  ],
  tooltip: {
    enabledOnSeries: true,
    shared: true,
    followCursor: false,
    intersect: false,
    inverseOrder: false,
    fixed: {
      enabled: true,
      position: 'bottomRight', // topRight, topLeft, bottomRight, bottomLeft
      offsetY: 0,
      offsetX: 0
    }
  },
  legend: {
    horizontalAlign: 'left',
    offsetX: 30
  }
}

const PRODUCT_HISTORY_CHART_DATA_TEMPLATE = [
  {
    translationKey: 'transaction_count',
    type: 'column',
    data: []
  },
  {
    translationKey: 'sold_count',
    type: 'column',
    data: []
  },
  {
    translationKey: 'revenue',
    type: 'line',
    data: []
  },
  {
    translationKey: 'display_count',
    type: 'line',
    data: []
  }
]

export default class extends Controller {
  static values = { data: Array, translations: Object }

  connect() {
    const chart = new ApexCharts(this.element, this.chartOptions())

    chart.render()
  }

  chartOptions() {
    const chartData = [...PRODUCT_HISTORY_CHART_DATA_TEMPLATE]
    chartData.forEach(template => template.name = this.translationsValue[template.translationKey])
    const chartDates = []
    const chartOptions = { ...DEFAULT_HISTORY_CHART_OPTIONS }
    chartOptions.yaxis.forEach(({title}) => title.text = this.translationsValue[title.translationKey])

    this.dataValue.map((historyDay, index) => {
      chartDates[index] = historyDay.date
      chartData[0].data[index] = historyDay.transactions // transakce
      chartData[1].data[index] = historyDay.quantity // quantity
      chartData[2].data[index] = historyDay.revenue // revenue
      chartData[3].data[index] = historyDay.display // impressions
    })
    chartOptions.xaxis.categories = chartDates
    chartOptions.series = chartData

    chartOptions.chart.type = 'line'
    chartOptions.chart.height= '300'

    return chartOptions
  }
}
