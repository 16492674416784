import { Alert } from 'tailwindcss-stimulus-components'

export default class AlertFlash extends Alert {
  static values = {
    delay: Number
  }

  show() {
    super.show();
    setTimeout(() => {
      this.close()
    }, this.delayValue);
  }
}
