import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String }

  async makeRequest(event) {
    event.preventDefault()

    const file = event.target.files[0]

    event.target.value = '' // clear file from file input

    // TODO: Handle errors
    await this.uploadFile(file)
  }

  uploadFile(file) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('dimension', this.dimensionValue)

    // this will automatically execute turbo stream commands
    const response = post(this.urlValue, {
      body: formData,
      responseKind: 'turbo-stream'
    })

    document.getElementById('noProducts').classList.add('hidden')
    return response
  }

  get dimensionValue() {
    const dimensionField = document.getElementById('marketing_dimension')
    return dimensionField.value
  }
}
