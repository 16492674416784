import { Controller } from '@hotwired/stimulus'

import ApexCharts from 'apexcharts'
import moment from 'moment'

const DEFAULT_CHART_OPTIONS = {
  chart: {
    type: 'area',
    animations: {
      enabled: false,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
        enabled: false
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350
      }
    },
    zoom: {
      enabled: false
    },
    toolbar: {
      show: false
    },
    fontFamily: 'Poppins, sans-serif'
  },
  colors: [
    '#3182ce',
    '#319795',
    '#805ad5',
    '#5a67d8',
    '#d53f8c',
    '#38a169',
    '#d69e2e',
    '#dd6b20',
    '#e53e3e',
    '#718096'
  ],
  fill: {
    type: 'gradient',
    gradient: {
      // shade: 'dark',
      type: 'vertical',
      shadeIntensity: 1,
      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      inverseColors: false,
      opacityFrom: 0.4,
      opacityTo: 0.7,
      stops: [0, 90, 100],
      colorStops: []
    }
  },
  stroke: {
    show: true,
    curve: 'smooth',
    lineCap: 'butt',
    colors: undefined,
    width: 2,
    dashArray: 0
  },
  markers: {
    size: 2,
    colors: [
      '#3182ce',
      '#319795',
      '#805ad5',
      '#5a67d8',
      '#d53f8c',
      '#38a169',
      '#d69e2e',
      '#dd6b20',
      '#e53e3e',
      '#718096'
    ],
    strokeColors: '#fff',
    strokeWidth: 2,
    strokeOpacity: 0.5,
    fillOpacity: 1,
    discrete: [],
    shape: 'circle',
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    onClick: undefined,
    onDblClick: undefined,
    hover: {
      size: 6,
      sizeOffset: 2
    }
  },
  tooltip: {
    x: {
      format: 'dd. MMM yyyy'
    }
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: function(value, timestamp) {
        return moment(new Date(timestamp)).format('DD. MMM YYYY')
      }
    }
  },
  legend: {
    fontFamily: 'Poppins, sans-serif'
  },
  yaxis: {
    tickAmount: 3,
    labels: {
      show: true,
      formatter: function(val) {
        return chartFormatter(val, rootState.users.user.locale)
      }
    }
  },
  dataLabels: {
    enabled: false,
    formatter: function(val) {
      return chartFormatter(val, rootState.users.user.locale)
    }
  }
}

export default class extends Controller {
  static values = { data: Object }

  connect() {
    const chart = new ApexCharts(this.element, this.chartOptions())

    chart.render()
  }

  chartOptions() {
    const chartOptions = DEFAULT_CHART_OPTIONS

    chartOptions.yaxis.labels = {
      show: true,
      offsetX: 0,
      offsetY: 0,
      format: undefined,
      formatter: value => {
        if (value) {
          return `${Number(value).toLocaleString(this.dataValue.locale || 'cs', {
            style: 'currency',
            currency: this.dataValue.currency || 'CZK'
          })}`
        } else {
          return value
        }
      }
    }

    chartOptions.series = this.dataValue.series
    let seriesLength = chartOptions.series.length
    let chartHeight = 200

    if (seriesLength > 4) {
      chartHeight = 200 + seriesLength / 4 * 40
    }
    chartOptions.chart.type = 'area'
    chartOptions.chart.height= chartHeight
    return chartOptions
  }
}
