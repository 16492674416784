import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggle', 'hiddenField', 'frame']

  connect() {
    this.displayAllProducts()
  }

  switchMode() {
    if (this.hiddenFieldTarget.value == 'true') {
      this.displayAllProducts()
    }
    else {
      this.displayLimitPriceProducts()
    }

    this.frameTarget.reload()
  }

  displayAllProducts() {
    this.hiddenFieldTarget.value = 'false'
    this.toggleTarget.checked = false
    let src = new URL(this.frameTarget.src)
    src.searchParams.set('limit_price_products_only', false)
    this.frameTarget.setAttribute('src', src)
  }

  displayLimitPriceProducts() {
    this.hiddenFieldTarget.value = 'true'
    let src = new URL(this.frameTarget.src)
    this.toggleTarget.checked = true
    src.searchParams.set('limit_price_products_only', true)
    this.frameTarget.setAttribute('src', src)
  }
}
