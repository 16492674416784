import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["maxLimitAddedValue", "minLimitAddedValue", "maxLimitPriceField", "minLimitPriceField", "maxLimitType", "minLimitType",  "message"]

  nilCeiling() {
    this.maxLimitAddedValueTarget.value = ''
  }

  checkMaxMinValues() {
    this.showMessage(
      Number(this.maxLimitAddedValueTarget.value) <= Number(this.minLimitAddedValueTarget.value) &&
      this.maxLimitPriceFieldTarget.value == this.minLimitPriceFieldTarget.value &&
      this.maxLimitTypeTarget.value == this.minLimitTypeTarget.value
    )
  }

  showMessage(show) {
    show ? this.messageTarget.classList.remove('hidden') : this.messageTarget.classList.add('hidden')
  }
}
