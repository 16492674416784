import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['discountPool', 'addDiscountButton', 'discardDiscountButton', 'discardDiscountInput']

  addDiscount() {
    this.discountPoolTarget.classList.remove('hidden')
    this.addDiscountButtonTarget.classList.add('hidden')
    this.discardDiscountButtonTarget.classList.remove('hidden')
    this.discardDiscountInputTarget.setAttribute('disabled', 'disabled')
  }

  discardDiscount() {
    this.discountPoolTarget.classList.add('hidden')
    this.addDiscountButtonTarget.classList.remove('hidden')
    this.discardDiscountButtonTarget.classList.add('hidden')
    this.discardDiscountInputTarget.removeAttribute('disabled')
  }
}
