import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'toggleAll', 'checkBox', 'actionsContainer', 'actionButton', 'sendForm' ]

  connect() {
    this.updateToggleAllState()
    this.setActionsContainerVisibility(false)
  }

  get isIndeterminate() {
    const states = this.checkBoxTargets.map(checkbox => checkbox.checked)
    const uniqStates = new Set(states).size
    return uniqStates == 2
  }

  updateToggleAllState() {
      const allChecked = this.checkBoxTargets.every(checkbox => checkbox.checked);
      const anyChecked = this.checkBoxTargets.some(checkbox => checkbox.checked);

      this.toggleAllTarget.checked = allChecked;
      this.toggleAllTarget.indeterminate = anyChecked && !allChecked;
    }

  toggle(event) {
    if (this.isIndeterminate) {
      this.toggleAllTarget.indeterminate = true
      this.setActionsContainerVisibility(true)
    } else {
      this.toggleAllTarget.indeterminate = false
      this.toggleAllTarget.checked = this.checkBoxTargets[0].checked
      this.setActionsContainerVisibility(this.toggleAllTarget.checked)
    }
    this.updateControlTargets()
  }

  toggleAll(event) {
    const checked = this.isIndeterminate || !this.checkBoxTargets[0].checked

    this.checkBoxTargets.forEach(checkbox => {
      checkbox.checked = checked
      if (this.hasSendFormTarget) {
        checkbox.closest('form').requestSubmit()
      }
    })

    this.toggleAllTarget.checked = checked
    this.toggleAllTarget.indeterminate = false

    this.updateControlTargets()
    this.setActionsContainerVisibility(checked)
  }

  setActionsContainerVisibility(visible = false) {
    visible ? this.actionsContainerTarget.classList.remove('hidden') : this.actionsContainerTarget.classList.add('hidden')
  }

  updateControlTargets() {
    const filters = {}
    const ids = this.checkBoxTargets.reduce((results, checkbox) => {
      if (checkbox.checked) {
        results.push(checkbox.id)
        checkbox.dataset.checkboxGroupFilterNames?.split(' ').forEach(filterName => {
          filters[filterName] = (filters[filterName] || 0) + 1
        })
      }
      return results
    }, [])

    

    this.actionButtonTargets.forEach(el => {
      if (el.dataset.checkboxGroupFilterName && filters[el.dataset.checkboxGroupFilterName] !== ids.length) {
        this.setActionButtonVisibility(el, false)
      } else {
        this.setActionButtonVisibility(el, true)
      }
      let url = new URL(el.href)
      url.searchParams.delete('id[]')
      ids.forEach(id => {
        url.searchParams.append('id[]', id)
      })
      el.href = url.toString()
    })
  }

  setActionButtonVisibility(el, visible = false) {
    visible ? el.classList.remove('hidden') : el.classList.add('hidden')
  }
}
