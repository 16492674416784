import { Controller } from '@hotwired/stimulus'
import { navigator } from "@hotwired/turbo"

export default class extends Controller {
  static targets = ['link', 'pagination', 'field']

  updateQueryParams() {
    const url = new URL(window.location.href)
    this.fieldTargets.forEach(field => {
      url.searchParams.set(field.name, field.value)
    })
    navigator.history.push(url)
    this.changeUrls()
  }

  updateFeedId(e) {
    const link = this.linkTargets.find(link => link.contains(e.target))

    const src = new URL(link.href)
    const url = new URL(window.location.href)

    const feedId = src.pathname.split('/').pop()
    url.searchParams.set('id', feedId)

    navigator.history.push(url)
  }

  updatePage(e) {
    const src = new URL(e.detail.url)
    const url = new URL(window.location.href)

    url.searchParams.set('page', src.searchParams.get('page'))

    navigator.history.push(url)
  }

  // copies selected filter & query parameters into feed and pagination links
  changeUrls() {
    const url = new URL(window.location.href)
    url.searchParams.delete('page')

    this.linkTargets.forEach(link => {
      const src = new URL(link.href)
      src.search = url.search

      link.setAttribute('href', src.toString())
    })

    this.paginationTarget.querySelectorAll('.page a').forEach(link => {
      const src = new URL(link.href)
      let pageParam = src.searchParams.get('page')

      src.search = url.search
      src.searchParams.set('page', pageParam)

      link.setAttribute('href', src.toString())
    })
  }

  clearQuery() {
    document.getElementById('query').value = ''
  }
}
