import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'spinner' ]
  static values = { reload: Boolean, status: String }

  connect() {
    if (this.reloadValue == true && this.statusValue == 'finished') {
      this.finishDownload()
      location.reload()
    }
  }

  download() {
    this.spinnerTarget.classList.remove('hidden')
    this.spinnerTarget.parentElement.classList.add('btn-blue-disabled')
  }

  finishDownload() {
    this.spinnerTarget.classList.add('hidden')
    this.spinnerTarget.parentElement.classList.remove('btn-blue-disabled')
  }
}
