import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

export default class extends Controller {
  connect() {
    $('.basic-multiple-select').select2({
      placeholder: "Select an option",
      debug: true,
      tags: true
    });
    $('.limited-multiple-select').select2({
      placeholder: "Select an option",
      debug: true,
      tags: false
    })
    $('.basic-single-select').select2({
      debug: true,
      tags: false,
      minimumResultsForSearch: -1
    })
  }

  destroy() {
    $('.basic-multiple-select').select2('destroy');
    $('.limited-multiple-select').select2('destroy');
    $('.basic-single-select').select2('destroy')
  }
}
