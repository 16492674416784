import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = { updateUrl: String, delay: Number }
  static targets = ['field', 'spinner', 'success', 'failure']
  static debounces = ['update']

  connect() {
    useDebounce(this, { wait: this.delayValue })
  }

  clear() {
    [this.spinnerTarget, this.failureTarget, this.successTarget].forEach(element => {
      element.classList.add('hidden')
    })
  }

  async update() {
    this.spinnerTarget.classList.remove('hidden')
    const response = await this.makeRequest()
    this.spinnerTarget.classList.add('hidden')

    if (response.ok) {
      if (this.fieldTarget.value !== '') {
        this.successTarget.classList.remove("hidden")
      } else {
        this.successTarget.classList.add("hidden")
      }
    } else {
      this.failureTarget.classList.remove("hidden")
      this.successTarget.classList.add("hidden")
    }
  }

  makeRequest(field) {
    const formData = new FormData();
    if(field) {
      formData.append(field.name, field.value)

      return post(this.updateUrlValue, { body: formData })
    } else {
      formData.append(this.fieldTarget.name, this.fieldTarget.value)

      return post(this.updateUrlValue, { body: formData })
    }
  }
}


