import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ['icon', 'content']

  mouseover() {
    this.removeTooltip()

    let rect = this.iconTarget.getBoundingClientRect()
    let tooltip = document.createElement('div')

    tooltip.setAttribute('id', 'floating-tooltip')
    tooltip.setAttribute('class', 'fixed z-100')
    tooltip.setAttribute('style', 'left: ' + rect.left + 'px; top: ' + rect.top + 'px;')

    document.body.prepend(tooltip)

    let content = this.contentTarget.cloneNode(true)

    content.classList.remove('hidden')
    tooltip.appendChild(content)
  }

  mouseleave() {
    this.removeTooltip()
  }

  removeTooltip() {
    let tooltip = document.getElementById('floating-tooltip')
    if (tooltip) tooltip.remove()
  }
}
