import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation'
import { format, intervalToDuration, parse } from 'date-fns'
import { enUS, cs, de } from 'date-fns/locale'
import 'chartjs-adapter-date-fns'

Chart.register(annotationPlugin)

export default class extends Controller {
  static targets = ['chart']
  static values = {
    stats: Object,
    from: String,
    to: String,
    userLocale: String,
    currency: String,
    translationProfit: String,
    translationRevenue: String,
  }

  connect() {
    let currency = this.currencyValue
    const userLocale = this.userLocaleValue
    const locales = { cs: cs, en: enUS, de: de }
    const fromDate = new Date(this.fromValue)
    const toDate = new Date(this.toValue)

    // the canvas needs to be cleared from any previous charts before it can be reused for a new chart
    // this behaviour is important when switching the instances
    let previous_chart = Chart.getChart("dashboard-main-chart")
    if (previous_chart) {
      previous_chart.reset()
    }

    let chart = new Chart(this.chartTarget, {
      type: 'line',
      spanGaps: false,
      data: {
        datasets: [
          {
            label: this.translationRevenueValue,
            data: this.getMetrics('revenue'),
            fill:  'origin',
            borderWidth: 2,
            borderColor: 'rgb(128, 90, 213)',
            backgroundColor: 'rgba(128, 90, 213, 0.1)',
            pointRadius: 0,
            pointHitRadius: 20,
            pointHoverRadius: 7,
            pointBackgroundColor: 'rgba(128, 90, 213, 0.2)',
            borderJoinStyle: 'round',
            yAxisID: 'y',
            tension: 0.2,
          },
          {
            label: this.translationProfitValue,
            data: this.getMetrics('profit'),
            fill: 'origin',
            borderWidth: 2,
            borderColor: 'rgb(49, 130, 206)',
            backgroundColor: 'rgba(49, 130, 206, 0.1)',
            pointRadius: 0,
            pointHitRadius: 20,
            pointHoverRadius: 7,
            pointBackgroundColor: 'rgba(49, 130, 206, 0.2)',
            borderJoinStyle: 'round',
            yAxisID: 'y',
            tension: 0.2,
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
              borderWidth: 1.5,
            },
            type: 'time',
            time: {
              stepSize: this.getStepSize(),
              unit: 'day'
            },
            min: {
              callback: (value) => format(fromDate, { locale: locales[userLocale] })
            },
            max: {
              callback: (value) => format(toDate, { locale: locales[userLocale]})
            },
            ticks: {
              callback: (value, index, ticks) => format(parse(value, 'MMM dd', new Date()), 'do MMM' , { locale: locales[userLocale] })
            },
          },
          y: {
            min: 0,
            title: {
              display: true,
              text: this.currencyValue,
            },
            position: 'left',
            display: true,
            grid: {
              borderWidth: 1.5
            }
          },
        },
        datasets: {
          line: {
            showline: false
          }
        },
        interaction: {
          mode: 'nearest',
          intersect: false,
        },
        plugins: {
          tooltip: {
            usePointStyle: true, // taking over the data points style
            enabled: true,
            position: 'nearest',
            callbacks: {
              label: (context) => {
                let label = context.dataset.label
                label += `: ${Math.round(context.parsed.y * 100) / 100} ${currency}`

                return label
              },
              title: (context) => format(new Date(context[0].parsed.x), 'dd. MMMM yyyy', { locale: locales[userLocale] })
            }
          },
          legend: {
            labels: {
              usePointStyle: true,
            },
            onHover: (event, legendItem) => {
              event.native.target.style.cursor = 'pointer'
              legendItem.fontColor = '#000000'
              event.chart.render();
            },
            onLeave: (event, legendItem) => {
              event.native.target.style.cursor = 'default'
              legendItem.fontColor = '#666'
              event.chart.render();
            },
            title: {
              display: true,
              text: '',
            },
            position: 'bottom',
            align: 'center',
          },
          title: {
            display: false,
          },
        }
      }
    })
  }

  getMetrics(metric) {
    let data = []
    const metrics = this.statsValue
    const from = this.fromValue
    const to = this.toValue

    for (let d = new Date(from); d <= new Date(to); new Date(d.setDate(d.getDate() + 1))) {
      let date = format(d, 'yyyy-MM-dd')

      if (metrics[date]) {
        let value = metrics[date][metric]

        if (value) {
          data.push({ x: date, y: value})
        }
      } else {
        data.push({ x: date, y: null })
      }
    }

    return data
  }

  duration() {
    return intervalToDuration({
      start: new Date(this.fromValue),
      end: new Date(this.toValue)
    })
  }

  getStepSize() {
    let duration = this.duration()

    if (duration['months'] > 0 || duration['years'] > 0) {
      return 7
    } else if (duration.days > 15 && duration['months'] == 0 && duration['years'] == 0) {
      return 4
    } else if (duration.days < 8 && duration['months'] == 0 && duration['years'] == 0) {
      return 2
    } else {
      return 3
    }
  }

}
