import { Controller } from '@hotwired/stimulus'
import { customAlphabet } from 'nanoid'

export default class extends Controller {
  static targets = [ "template", "container", "item" ]

  add() {
    const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz_-ABCDEFGHIJKLMNOPQRSTUVWXYZ', 20)
    let template = this.templateTarget.innerHTML.replace(/RANGE/g, nanoid())
    this.containerTarget.insertAdjacentHTML('beforeend', template)
  }

}
