import { Controller } from '@hotwired/stimulus'
import { useMutation } from "stimulus-use"

export default class extends Controller {
  static targets = ["frame", "loading", "control", "busyArea"]
  static values = {initial: Boolean}

  connect() {
    useMutation(this, { element: this.frameTarget, attributes: true })

    if (this.isBusy) {
      this.showLoading()
    }
  }

  mutate(entries) {
    entries.forEach(mutation => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'busy') {
        if (this.isBusy && !(this.initialValue && this.frameTarget.innerHTML !== '')) {
          this.showLoading()
        }
        this.controlTargets.forEach(control => {
          control.disabled = this.isBusy
          control.blur()
        })
      }
    })
  }

  showLoading() {
    const element = this.loadingTarget.content.firstElementChild
    if (this.hasBusyAreaTarget) {
      this.busyAreaTarget.innerHTML = element.outerHTML
    } else {
      this.frameTarget.innerHTML = element.outerHTML
    }
  }

  get isBusy() {
    return this.frameTarget.hasAttribute('busy')
  }
}
