import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'field', 'currency', 'value' ]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    if (this.isCurrency()) {
      this.currencyTarget.classList.remove('hidden')
    } else {
      this.currencyTarget.classList.add('hidden')
    }

    if (this.isFloat()) {
      this.valueTarget.setAttribute('step', 'any')
    } else {
      this.valueTarget.removeAttribute('step')
      this.valueTarget.setAttribute('value', parseInt(this.valueTarget.value))
    }
  }

  isCurrency() {
    const field = this.fieldTarget.value
    return field.toLocaleLowerCase().includes('price')
  }

  isFloat() {
    const field = this.fieldTarget.value
    return field.includes('float_')
  }
}
