import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  handleChange() {
    const value = this.inputTarget.checked
    const frame = this.element.closest('turbo-frame')
    const url = new URL(frame.src)

    url.searchParams.set("current_offer_only", value)
    frame.src = url.toString()
  }
}
