import { Controller } from '@hotwired/stimulus'
import { nanoid } from 'nanoid'
import zip from 'lodash/zip'

export default class extends Controller {
  static targets = [ "template", "container", "item" ]

  add() {
    const html = this.templateTarget.innerHTML.replace(/PLACEHOLDER/g, nanoid())
    this.containerTarget.insertAdjacentHTML('beforeend', html)
  }

  duplicate(event) {
    const source = this.findItem(event)
    const html = source.outerHTML.replace(new RegExp(source.dataset.index, 'g'), nanoid())
    source.insertAdjacentHTML('afterend', html)
    const copy = source.nextSibling

    // match field values in the copy with field values in the source
    zip(source.querySelectorAll('input,select'), copy.querySelectorAll('input,select'))
      .forEach(([sourceField, copyField]) => { copyField.value = sourceField.value })
  }

  remove(event) {
    const item = this.findItem(event)
    item.querySelector('[name*="[_destroy]"]').value = "true"
    item.classList.add('hidden')
  }

  insertCopy(item) {
    let html = item.outerHTML
    html = html.replace(new RegExp(item.dataset.index, 'g'), nanoid())
    this.containerTarget.insertAdjacentHTML('beforeend', html)
    return this.containerTarget.lastChild
  }

  findItem(event) {
    return this.itemTargets.find(item => item.contains(event.target))
  }
}
