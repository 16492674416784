import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'
import { patch } from '@rails/request.js'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = { updateDelay: Number, updateUrl: String, modalUrl: String }
  static debounces = ['update']
  static targets = ['field', 'spinner', 'success', 'failure', 'warning']

  connect() {
    useDebounce(this, { wait: this.updateDelayValue })
  }

  clear() {
    [this.spinnerTarget, this.failureTarget, this.warningTarget].forEach(element => {
      element.classList.add('hidden')
    })
  }

  showButtons() {
    this.hide(this.spinnerTarget)

    if (this.fieldTarget.value !== '') {
      this.show(this.successTarget)
    } else {
      this.hide(this.successTarget)
      this.show(this.warningTarget)
    }
  }

  clearField() {
    const startNumber = this.fieldTarget.defaultValue

    this.fieldTarget.value = startNumber
    this.hide(this.successTarget)
  }

  async update() {
    this.hide(this.successTarget)
    this.show(this.spinnerTarget)

    const response = await this.makeRequest()
    this.hide(this.spinnerTarget)

    if (response.ok) {
      if (this.fieldTarget.value !== '') {
        this.show(this.successTarget)
      } else {
        this.show(this.warningTarget)
        this.hide(this.successTarget)
      }
    } else {
      this.show(this.failureTarget)
      this.hide(this.successTarget)
    }
  }

  async updatePrice(event) {
    const fieldTarget = document.getElementById(event.target.getAttribute("data-product-identifier"))
    const response = await this.makeRequest(fieldTarget)
    const frame = document.getElementById('products');

    if (response.ok) {
      frame.reload()
    } else {
      this.show(this.failureTarget)
    }
  }

  async makeRequest(field) {
    const formData = new FormData();

    if (field instanceof PointerEvent) {
      // Called directly from HTML element using Stimulus action, null it
      field = field.target
      formData.append(field.name, field.value)

      await patch(this.updateUrlValue, { body: formData })

      this.dispatch("patch-done") // dispatch independently of response status
    } else if(field) {
      // Called internally within this controller
      formData.append(field.name, field.value)

      return patch(this.updateUrlValue, { body: formData })
    } else {
      // Called internally within this controller
      formData.append(this.fieldTarget.name, this.fieldTarget.value)

      return patch(this.updateUrlValue, { body: formData })
    }
  }

  async makeSure() {
    const formData = new FormData()
    formData.append(this.fieldTarget.name, this.fieldTarget.value)
    formData.append('previous_price', this.fieldTarget.defaultValue)

    return post(this.modalUrlValue, { body: formData, responseKind: 'turbo-stream' })
  }

  callModal(e) {
    let modal = document.getElementById('confirm_price_modal');
    let cancel = document.getElementById('modal-cancel');
    const fieldTarget = document.getElementById(e.target.getAttribute("data-product-identifier"));
    const successTarget = document.getElementById(`success-buttons-${modal.getAttribute('data-product-identifier')}`);

    modal.style.display = "none";
    if(e.target === cancel) {
      fieldTarget.value = fieldTarget.defaultValue;
      this.hide(successTarget)
    }
  }

  show(element) {
    element.classList.remove('hidden')
  }

  hide(element) {
    element.classList.add('hidden')
  }
}
