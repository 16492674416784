import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "activeButton", "finishedButton", "productsTable", "inactiveTests", "activeArrowDown", "activeArrowRight", "finishedArrowDown", "finishedArrowRight" ]

  active() {
    this.activeButtonTarget.classList.add('btn-blue')
    this.finishedButtonTarget.classList.remove('btn-blue')
    this.finishedButtonTarget.classList.add('hover:bg-gray-300', 'bg-gray-200')
    this.productsTableTarget.classList.remove('hidden')
    this.inactiveTestsTarget.classList.add('hidden')
    this.activeArrowDownTarget.classList.remove('hidden')
    this.activeArrowRightTarget.classList.add('hidden')
    this.finishedArrowDownTarget.classList.add('hidden')
    this.finishedArrowRightTarget.classList.remove('hidden')
  }

  finished() {
    this.activeButtonTarget.classList.remove('btn-blue')
    this.activeButtonTarget.classList.add('hover:bg-gray-300', 'bg-gray-200')
    this.finishedButtonTarget.classList.add('btn-blue')
    this.productsTableTarget.classList.add('hidden')
    this.inactiveTestsTarget.classList.remove('hidden')
    this.activeArrowDownTarget.classList.add('hidden')
    this.activeArrowRightTarget.classList.remove('hidden')
    this.finishedArrowDownTarget.classList.remove('hidden')
    this.finishedArrowRightTarget.classList.add('hidden')
  }
}
