import { Controller } from '@hotwired/stimulus'
import { navigator } from "@hotwired/turbo"

export default class extends Controller {

  close(e) {
    let fieldName = e.target.parentElement.id
    let url = new URL(window.location)
    url.searchParams.delete(fieldName)
    navigator.history.push(url)
    location.reload()
  }

  checkValidity(e) {
    if (e.target.value == '') {
      e.target.classList.add('border-red-500')
      e.target.classList.add('focus:border-red-500')
    } else {
      e.target.classList.remove('border-red-500')
      e.target.classList.remove('focus:border-red-500')
    }
  }
}
