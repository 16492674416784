import { Controller } from '@hotwired/stimulus'
import { visit } from "@hotwired/turbo"
import { format, subDays } from 'date-fns'
import { conformsTo } from 'lodash'
import max from 'lodash/max'
import min from 'lodash/min'

export default class extends Controller {
  static targets = ['interval', 'from', 'appliedFrom', 'to', 'appliedTo', 'frame' ]

  connect() {
    const url = new URL(window.location.href)

    if (url.search === '') {
      this.setInterval('last_week')
    } else {
      this.setFrom(url.searchParams.get('from'))
      this.setTo(url.searchParams.get('to'))
    }

    this.apply()
  }

  updateFrom() {
    this.setFrom(min([this.fromTarget.value, this.toTarget.value]))
    this.setInterval('')
  }

  updateTo() {
    this.setTo(max([this.toTarget.value, this.fromTarget.value]))
    this.setInterval('')
  }

  updateInterval() {
    this.setInterval(this.intervalTarget.value)
  }

  setFrom(value) {
    this.fromTarget.value = value
  }

  setTo(value) {
    this.toTarget.value = value
  }

  setInterval(value) {
    this.intervalTarget.value = value
    if (value !== '') this.setDatesFromInterval()
  }

  setDatesFromInterval() {
    const intervals = { 'last_week': 6, 'last_two_weeks': 13, 'last_four_weeks': 27 }
    const today = new Date(Date.now())
    let newStartDay = subDays(subDays(today, 0), intervals[this.intervalTarget.value])
    let newEndDay = subDays(today, 0)
    this.setFrom(format(newStartDay, 'yyyy-MM-dd'))
    this.setTo(format(newEndDay, 'yyyy-MM-dd'))
  }

  apply() {
    this.appliedFromTarget.innerHTML = format(this.from, 'dd. MM. yyyy')
    this.appliedToTarget.innerHTML = format(this.to, 'dd. MM. yyyy')
    this.reloadFrames()
  }

  reloadFrames() {
    this.frameTargets.forEach(frame => {
      frame.setAttribute('src', this.frameUrl(frame.baseURI))
      frame.reload()
    })
  }

  frameUrl(urlValue) {
    const url = new URL(urlValue)

    url.searchParams.set('interval', this.intervalTarget.value)
    url.searchParams.set('from', this.fromTarget.value)
    url.searchParams.set('to', this.toTarget.value)

    return url
  }

  reload() {
    const url = new URL(window.location.href)

    // main interval
    url.searchParams.set('interval', this.intervalTarget.value)
    url.searchParams.set('from', this.fromTarget.value)
    url.searchParams.set('to', this.toTarget.value)

    visit(url)
  }

  get from() {
    return new Date(this.fromTarget.value)
  }

  get to() {
    return new Date(this.toTarget.value)
  }
}
