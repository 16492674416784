import { Controller } from '@hotwired/stimulus'
import { nanoid } from 'nanoid'

export default class extends Controller {
  static targets = [ "dataStorage", "uploadData", "select", "tableTitle", 'template', 'body' ]

  connect() {
    this.setType()
  }

  setType() {
    if (this.selectTarget.value) {
      this.tableTitleTarget.innerHTML = this.selectTarget.querySelector(`option[value=${this.selectTarget.value}]`).innerHTML
    }
  }

  addRow() {
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, nanoid())
    this.bodyTarget.insertAdjacentHTML('beforeend', content)
  }

  removeItem(event) {
    const item = this.findItem(event)
    item.querySelector('[name*="[_destroy]"]').value = "true"
    item.classList.add('hidden')
  }

  findItem(event) {
    return this.dataStorageTargets.find(element => element.contains(event.target))
  }
}
