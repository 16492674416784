import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['soldOut', 'soldOutCell', 'amount', 'priorPrice', 'marketingPrice', 'dimension', 'button', 'exclamationIcon', 'fakeButton']

  connect() {
    if (this.soldOutTarget.value === "true") {
      this.dimensionTarget.readOnly = true
      this.dimensionTarget.classList.add('text-gray-400', 'w-1/2')
      this.dimensionTarget.classList.remove('w-full')

      this.marketingPriceTarget.readOnly = true
      this.marketingPriceTarget.classList.add('text-gray-400')

      this.priorPriceTarget.readOnly = true
      this.priorPriceTarget.classList.add('text-gray-400', 'placeholder-gray-400')
      this.priorPriceTarget.classList.remove('placeholder-gray-700')

      this.exclamationIconTarget.classList.add('hidden')

      this.amountTarget.readOnly = true
      this.amountTarget.classList.add('text-gray-400')

      this.buttonTarget.classList.add('hidden')
      this.fakeButtonTarget.classList.remove('hidden')
      this.soldOutCellTarget.classList.remove('hidden')
    }
  }
}
