import { Controller } from '@hotwired/stimulus'
import max from 'lodash/max'
import min from 'lodash/min'

export default class extends Controller {
  static targets = ['from', 'to']

  connect() {
    this.fromTarget.max = this.toTarget.value
    this.toTarget.min = new Date(this.toTarget.min) > new Date(this.fromTarget.value) ? this.toTarget.min : this.fromTarget.value
  }

  updateFrom() {
    this.from = min([this.fromTarget.value, this.toTarget.value])
  }

  updateTo() {
    this.to = max([this.toTarget.value, this.fromTarget.value])
  }

  set from(value) {
    this.fromTarget.value = this.toTarget.min = value
  }

  set to(value) {
    this.toTarget.value = this.fromTarget.max = value
  }
}
