import {Controller} from '@hotwired/stimulus'
import {nanoid} from 'nanoid'

export default class extends Controller {
  static targets = ['template', 'container', 'selectField', 'name', 'operator', 'values']

  connect() {
    this.updateVisibility()
  }

  add() {
    let html = this.templateTarget.innerHTML.replace(/UID/g, nanoid())
    this.containerTarget.insertAdjacentHTML('beforeend', html)
    this.updateVisibility()
  }

  addAllProducts() {
    let html = this.templateTarget.innerHTML.replace(/<option value="all_products" csv-check-values="false">/g, "<option value='all_products' selected='selected' csv-check-values='false'>").replace(/UID/g, nanoid())
    this.containerTarget.insertAdjacentHTML('beforeend', html)
    this.updateVisibility()
  }

  remove(e) {
    let dimensionForm = e.target.closest('.dimension_form')
    dimensionForm.remove()
  }

  updateVisibility() {
    var targets = this.selectFieldTargets
    targets.forEach((target) => {
      var visibility = target.options[target.selectedIndex].getAttribute('csv-check-values')
      var button = target.parentElement.getElementsByClassName("csv_upload_button")[0]
      if (visibility === 'true') {
        button.classList.remove('hidden')
      } else {
        button.classList.add('hidden')
      }
    })

    this.toggleOtherFields()
  }

  changeName() {
    let name = this.nameTarget.value
    let element = document.getElementById('product_set_name')
    element.value = name
    element.dispatchEvent(new Event('input'))
  }

  toggleOtherFields() {
    let allProductsField = this.selectFieldTargets.filter(field => field.value === 'all_products')

    if (allProductsField.length > 0) {
      allProductsField.forEach(field => {
        let operator = field.nextElementSibling
        let values = operator.closest('.dimension_form').children[1]
        operator.classList.add('hidden')
        values.classList.add('hidden')
      })
    } else {
      this.operatorTargets.forEach(operator => {
        operator.classList.remove('hidden')
      })
      this.valuesTargets.forEach(values => {
        values.classList.remove('hidden')
      })
    }
  }
}
