import { Controller } from '@hotwired/stimulus'
import { navigator } from "@hotwired/turbo"

export default class extends Controller {
  static targets = ['frame']

  connect() {
    // without initially disabling the turbo frame, fetching starts before the
    // event listener is registered, so we cannot intercept the request
    this.frameTarget.removeAttribute('disabled')
  }

  addProduct(e) {
    const url = new URL(window.location.href)
    url.searchParams.set('product_id', this.productId(e.detail.url))
    navigator.history.push(url)
  }

  removeProduct() {
    const url = new URL(window.location.href)
    url.searchParams.delete('product_id')
    navigator.history.push(url)

    // allows the same product to be opened again
    this.frameTarget.removeAttribute('src')
  }

  productId(url) {
    const encodedLastSegment = url.pathname.split('/').pop();

    return decodeURIComponent(encodedLastSegment);
  }
}
