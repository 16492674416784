import { Application } from '@hotwired/stimulus'
import Reveal from "stimulus-reveal-controller"
import { Dropdown } from "tailwindcss-stimulus-components"

window.Stimulus = Application.start()

Stimulus.register("reveal", Reveal)
Stimulus.register('dropdown', Dropdown)

export default Stimulus
