import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggle']

  // Click using JS to keep button animation well working
  submitToggle() {
    this.toggleTarget.click()
  }
}
