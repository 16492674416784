import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['selectedGroupsRadioButtonPool', 'selectedGroupsPool', 'allGroupsRadioButton', 'selectedGroupsRadioButton', 'roleInput']
  static lastEditorRoleType = undefined

  connect() {
    this.updateLastEditorRoleType()
  }

  updateGroups() {
    if (this.selectedRole() == 'editor') {
      this.selectedGroupsRadioButtonPoolTarget.classList.remove('hidden')

      if (this.lastEditorRoleType == 'selected_groups') {
        this.selectedGroupsRadioButtonTarget.click()
      } else {
        this.allGroupsRadioButtonTarget.click()
      }
    } else {
      this.selectedGroupsRadioButtonPoolTarget.classList.add('hidden')
      this.allGroupsRadioButtonTarget.click()
    }
  }

  selectedRole() {
    return this.roleInputTargets.find(input => input.checked).value
  }

  roleType() {
    return [this.allGroupsRadioButtonTarget, this.selectedGroupsRadioButtonTarget].find(input => input.checked).value
  }

  updateLastEditorRoleType() {
    if (this.selectedRole() == 'editor') { this.lastEditorRoleType = this.roleType() }
  }
}
