import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'name', 'competitor', 'minDiff', 'diffType', 'competitorsCount', 'nameField', 'selectedIcon',  'selectedText']

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    this.fieldNames.forEach(fieldName => {
      const wrapper = this[`${fieldName}Target`].parentElement

      this.fieldVisible(fieldName) ? wrapper.classList.remove('hidden') : wrapper.classList.add('hidden')
    })
  }

  fieldVisible(name) {
    return {
      'name': true,
      'competitor': this.competitorApplicable(),
      'minDiff': this.minDiffApplicable(),
      'diffType': this.diffTypeApplicable(),
      'competitorsCount': this.competitorsCountApplicable()
    }[name]
  }

  competitorApplicable() {
    return [
      'competitor_exists',
      'competitor_not_exists',
      'cheaper_than',
      'more_expensive_than',
      'competitor_in_stock',
      'competitor_not_in_stock',
    ].includes(this.name)
  }

  minDiffApplicable() {
    return [
      'cheapest',
      'most_expensive',
      'cheaper_than',
      'more_expensive_than',
    ].includes(this.name)
  }

  diffTypeApplicable() {
    return [
      'cheapest',
      'most_expensive',
      'cheaper_than',
      'more_expensive_than',
    ].includes(this.name)
  }

  competitorsCountApplicable() {
    return [
      'x_competitors_exist'
    ].includes(this.name)
  }

  updateName(element) {
    this.nameTarget.value = element.target.dataset.value
    this.nameFieldTarget.innerHTML = element.target.textContent
    this.selectedIconTargets.forEach(element => {
      element.getAttribute('data-value') == this.nameTarget.value ? element.classList.remove('hidden') : element.classList.add('hidden')
    })
    this.selectedTextTargets.forEach(element => {
      const classes = ['text-gray-900', 'font-normal']
      element.getAttribute('data-value') == this.nameTarget.value ? element.classList.add(...classes) : element.classList.remove(...classes)
    })
  }

  get name() {
    return this.nameTarget.value
  }

  get fieldNames() {
    return ['name', 'competitor', 'minDiff', 'diffType', 'competitorsCount']
  }
}
