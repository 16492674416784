import { Controller } from '@hotwired/stimulus'
import { nanoid } from 'nanoid'
import { useTargetMutation, useDebounce } from 'stimulus-use'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = [ 'template', 'container', 'name', 'item', 'applyButton', 'spinner', 'value', 'form']
  static values = { productsPath: String, productsCountPath: String, removeUrl: String }
  static debounces = ['saveChanges']

  connect() {
    // stimulus doesn't yet have target changed callbacks
    useTargetMutation(this, { targets: ['item'] })
    useDebounce(this, { wait: 100 })
    this.add()
  }

  saveChanges() {
    this.formTarget.requestSubmit()
  }

  async removeProduct({params: {rowId}}) {
    if (!rowId) {
      return // prevent the error when trying to delete non-existing data storage
    }
    const url = this.removeUrlValue.replace('ID', rowId)
    const response = await post(url, {
      responseKind: 'turbo-stream'
    })
  }

  add() {
    let html = this.templateTarget.innerHTML.replace(/UID/g, nanoid())
    this.containerTarget.insertAdjacentHTML('beforeend', html)
  }

  remove(e) {
    let dimensionForm = e.target.closest('.dimension_form').parentElement
    dimensionForm.remove()
  }

  displaySpinner() {
    this.spinnerTarget.classList.remove('hidden')
    this.applyButtonTarget.classList.add('btn-disabled')
  }

  hideSpinner() {
    this.applyButtonTarget.classList.toggle('btn-disabled')
    this.spinnerTarget.classList.toggle('hidden')
  }

  valueTargetConnected() {
    this.requestProductsCount()
  }

  valueTargetDisconnected() {
    this.requestProductsCount()
  }

  itemTargetAdded() {
    this.requestProductsCount()
  }

  itemTargetRemoved() {
    this.requestProductsCount()
  }

  itemTargetChanged() {
    this.requestProductsCount()
  }

  async makeProductsRequest() {
    const formData = new FormData(this.formTarget)
    formData.delete('_method')

    await post(this.productsPathValue, {
      body: formData,
      responseKind: 'turbo-stream',
    })

    this.hideSpinner()
  }

  async requestProductsCount() {
    const formData = new FormData(this.formTarget)
    formData.delete('_method')

    await post(this.productsCountPathValue, {
      body: formData,
      responseKind: 'turbo-stream',
    })
  }
}
