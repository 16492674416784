import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "usersButton", "staticDemoButton", "usersSection", "staticDemoSection" ]

  selectUsers() {
    this.usersSectionTarget.classList.remove("hidden")
    this.staticDemoSectionTarget.classList.add("hidden")
    this.usersButtonTarget.classList.remove("btn-outline")
    this.staticDemoButtonTarget.classList.add("btn-outline")
  }

  selectStaticDemo() {
    this.usersSectionTarget.classList.add("hidden")
    this.staticDemoSectionTarget.classList.remove("hidden")
    this.usersButtonTarget.classList.add("btn-outline")
    this.staticDemoButtonTarget.classList.remove("btn-outline")
  }
}
