import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'selectField', 'intervalSelect', 'intervalField', 'interval', 'conversionTooltip', 'turnoverTooltip', 'turnoverFlex', 'turnoverSeparation' ]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    this.updateIntervalItems()

    if (this.selectFieldTarget.value === 'conversion_rate') {
      this.updateConversionItems()
    } else if (this.selectFieldTarget.value === 'stock_turnover') {
      this.updateTurnoverItems()
    } else {
      this.turnoverTooltipTargets.forEach(element => {
        element.classList.add('hidden')
      })
      this.conversionTooltipTarget.classList.add('hidden')
      this.turnoverFlexTarget.classList.remove('flex', 'flex-col')
      this.turnoverSeparationTarget.classList.remove('mt-8')
    }
  }

  updateIntervalItems() {
    if (this.intervalSelectTarget.value === 'custom') {
      this.intervalFieldTarget.classList.remove('hidden')
    } else {
      this.intervalFieldTarget.classList.add('hidden')
    }
  }

  updateConversionItems() {
    this.conversionTooltipTarget.classList.remove('hidden')
    this.turnoverTooltipTargets.forEach(element => {
      element.classList.add('hidden')
    })
    this.turnoverFlexTarget.classList.remove('flex', 'flex-col')
    this.turnoverSeparationTarget.classList.remove('mt-8')
  }

  updateTurnoverItems() {
    this.turnoverTooltipTargets.forEach(element => {
      element.classList.remove('hidden')
    })
    this.conversionTooltipTarget.classList.add('hidden')
    this.turnoverFlexTarget.classList.add('flex', 'flex-col')
    this.turnoverSeparationTarget.classList.add('mt-8')
  }

  updateInterval() {
    if (this.intervalSelectTarget.value !== 'custom') {
      this.intervalTarget.value = this.intervalSelectTarget.value
    } else if (this.intervalFieldTarget.value !== '') {
      this.intervalTarget.value = `days_${this.intervalFieldTarget.value}`
    } else {
      this.intervalTarget.value = 'days_30'
    }
  }

  limitInterval() {
    if (this.interval && this.interval > 30) {
      this.intervalFieldTarget.value = '30'
    }
  }

  get interval() {
    if (this.intervalFieldTarget.value !== '') {
      return parseInt(this.intervalFieldTarget.value)
    }
  }
}
