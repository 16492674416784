import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['list', 'toggle', 'item']

  connect() {
    this.updateVisibility()
  }

  toggle() {
    this.updateItems(this.toggleTarget.checked)
    this.updateVisibility()
  }

  selectAll() {
    this.updateItems(true)
  }

  deselectAll() {
    this.updateItems(false)
  }

  updateItems(checked) {
    this.itemTargets.forEach(item => item.checked = checked)
  }

  updateVisibility() {
    if (this.toggleTarget.checked) {
      this.listTarget.classList.remove('hidden')
    } else {
      this.listTarget.classList.add('hidden')
    }
  }
}
