import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { reload: Boolean, status: String }

  connect() {
    if (this.statusValue === 'approved' && this.reloadValue === true) {
      location.reload();
    }
  }
}
