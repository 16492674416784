import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "dataType", "cron", "customTime", 'hours', 'minutes' ]
  static values = { cron: String, dataType: String }

  connect() {
    this.setCron()
    this.change()
  }

  change() {
    if (['product_feed', 'shopify_product_feed'].includes(this.dataTypeValue) && this.cronSelectedId == 'once_per_day') {
      this.customTimeTarget.classList.remove('hidden')
      this.hoursTarget.removeAttribute('disabled')
      this.minutesTarget.removeAttribute('disabled')
    } else {
      this.customTimeTarget.classList.add('hidden')
      this.hoursTarget.setAttribute('disabled', true)
      this.minutesTarget.setAttribute('disabled', true)
    }
  }

  get cronSelectedId() {
    return this.cronTarget.options[this.cronTarget.selectedIndex].id
  }

  setCustomCronTime() {
    const opt = document.getElementById('once_per_day')
    opt.value = [this.minutesTarget.value, this.hoursTarget.value, '* * *'].join(' ')
  }

  setCron() {
    if (['product_feed', 'google_shopping_feed', 'shopify_product_feed'].includes(this.dataTypeValue)) {
      this.cronTarget.querySelector(`option`).text = 'Každou lichou hodinu'
      this.cronTarget.querySelector(`option`).value = '0 1-23/2 * * *'
      const opt = document.createElement("option");

      if (['product_feed', 'shopify_product_feed'].includes(this.dataTypeValue)) {
        const once_per_day = this.cronValue.match(/\d+ \d+ \* \* \*/) != null
        const min_hour = this.cronValue.match(/^\d+|\d+\b|\d+(?=\w)/g).map(function (v) {return +v})

        opt.text = 'Jednou denně';
        opt.id = 'once_per_day'
        if (once_per_day) {
          opt.selected = true
          this.hoursTarget.value = min_hour[1]
          this.minutesTarget.value = min_hour[0]
          opt.value = [this.minutesTarget.value, this.hoursTarget.value, '* * *'].join(' ')
        }
        this.cronTarget.appendChild(opt);
      }

      if (this.cronValue != this.cronTarget.querySelector(`option`).value && this.cronValue != opt.value && this.cronValue.match(/\d+ \d+ \* \* \*/) == null) {
        const node = document.createElement("option");
        node.text = this.cronValue;
        node.value = this.cronValue;
        node.selected = true;
        this.cronTarget.appendChild(node);
      }
    } else if (['transaction_feed', 'shopify_transaction_feed'].includes(this.dataTypeValue)) {
      this.cronTarget.querySelector(`option`).text = 'Každý den ve 02:00'
      this.cronTarget.querySelector(`option`).value = '0 2 * * *'

      const opt = document.createElement("option");
      opt.text = 'Každý den ve 03:30';
      opt.value = '30 3 * * *';
      if (opt.value == this.cronValue) {
        opt.selected = true;
      }
      this.cronTarget.appendChild(opt);

      const opt2 = document.createElement("option");
      opt2.text = 'Každou lichou hodinu'
      opt2.value = '0 1-23/2 * * *'
      if (opt2.value == this.cronValue) {
        opt2.selected = true;
      }
      this.cronTarget.appendChild(opt2);

      const opt3 = document.createElement("option");
      opt3.text = 'Každý den v 05:00'
      opt3.value = '0 5 * * *'
      if (opt3.value == this.cronValue) {
        opt3.selected = true;
      }
      this.cronTarget.appendChild(opt3);

      if (this.cronValue != this.cronTarget.querySelector(`option`).value && this.cronValue != opt.value && this.cronValue != opt2.value ) {
        const node = document.createElement("option");
        node.text = this.cronValue;
        node.value = this.cronValue;
        node.selected = true;
        this.cronTarget.appendChild(node);
      }
    } else if (this.dataTypeValue == 'sortiment_report') {
      this.cronTarget.querySelector(`option`).text = 'Každý den ve 02:00'
      this.cronTarget.querySelector(`option`).value = '0 2 * * *'

      if (this.cronValue != this.cronTarget.querySelector(`option`).value ) {
        const node = document.createElement("option");
        node.text = this.cronValue;
        node.value = this.cronValue;
        node.selected = true;
        this.cronTarget.appendChild(node);
      }
    } else if (this.dataTypeValue == 'ga4') {
      this.cronTarget.querySelector(`option`).text = 'Každý den ve 03:00'
      this.cronTarget.querySelector(`option`).value = '0 3 * * *'

      if (this.cronValue != this.cronTarget.querySelector(`option`).value ) {
        const node = document.createElement("option");
        node.text = this.cronValue;
        node.value = this.cronValue;
        node.selected = true;
        this.cronTarget.appendChild(node);
      }
    } else if (['heureka_feed', 'price_check'].includes(this.dataTypeValue)) {
      this.cronTarget.querySelector(`option`).text = 'Každý den v 03:00 a 12:00'
      this.cronTarget.querySelector(`option`).value = '0 3,12 * * *'

      if (this.cronValue != this.cronTarget.querySelector(`option`).value ) {
        const node = document.createElement("option");
        node.text = this.cronValue;
        node.value = this.cronValue;
        node.selected = true;
        this.cronTarget.appendChild(node);
      }
    }
  }

  authChange(event) {
    const selectedValue = event.target.value
    const instanceId = this.data.get("instanceIdValue")
    const newUrl = `/admin/instances/${instanceId}/ga4_properties?ga4_auth_record_id=${selectedValue}`
    const turboFrame = document.querySelector('[data-properties="ga4PropertiesFrame"]')

    if (turboFrame) {
      turboFrame.classList.add('hidden')
      turboFrame.src = newUrl
    } else {
      console.error("Turbo Frame with ID 'ga4PropertiesFrame' not found.")
    }
  }
}
