import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'exportRemoveProducts', "container", "template", 'onlyChanged'
  ]

  setOnlyChanged() {
    if (this.onlyChangedTarget.checked) {
      this.exportRemoveProductsTarget.classList.remove('hidden')
    } else {
      this.exportRemoveProductsTarget.classList.add('hidden')
    }
  }
}
