import { Controller } from '@hotwired/stimulus'
import { nanoid } from 'nanoid'
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static targets = [ 'dataStorage', 'dimension', 'tableTitle', 'data', 'template', 'body', 'count', 'manualProducts', 'noProducts', 'spinner', 'form' ]
  static values = { url: String}

  connect() {
    useDebounce(this, { wait: 10 })
    this.setType()
    this.displayNoProducts()
  }

  show(event) {
    if (event.target.value != '') {
      this.dataTarget.classList.remove('hidden')
    } else {
      this.dataTarget.classList.add('hidden')
    }
  }

  setType() {
    if (this.dimensionTarget.value) {
      this.tableTitleTarget.innerHTML = this.dimensionTarget.querySelector(`option[value=${this.dimensionTarget.value}]`).innerHTML
    }
  }

  addRow() {
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, nanoid())
    this.bodyTarget.insertAdjacentHTML('beforeend', content)
    this.manualProductsTarget.classList.remove('hidden')
    this.noProductsTarget.classList.add('hidden')
  }

  removeItem(event) {
    const targetedDataStorage = this.dataStorageTargets.find(element => element.contains(event.target))
    targetedDataStorage.remove()

    let manualProduct = document.getElementById('new_data_storage')
    if (!manualProduct) {
      this.manualProductsTarget.classList.add('hidden')
    }
  }

  displayNoProducts() {
    const count = this.dataStorageTargets.length
    if (count == 0) {
      this.noProductsTarget.classList.remove('hidden')
    } else {
      this.noProductsTarget.classList.add('hidden')
    }
  }

  hideNoProducts() {
    this.noProductsTarget.classList.add('hidden')
  }

  disableEnter(e) {
    if (e.keyCode == 13) {
      e.preventDefault()
    }
  }
}
