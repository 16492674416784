import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["nameField", "nameTextField", "frame"]

  static values = {
    autoReductionTranslation: String,
    reductionTranslation: String
  }

  updateName(event) {
    this.nameFieldTarget.value = event.target.value
  }

  updateNameWithTranslation() {
    if (this.nameTextFieldTarget.value === this.autoReductionTranslationValue) {
      this.nameTextFieldTarget.value = this.reductionTranslationValue
      this.nameFieldTarget.value = this.reductionTranslationValue
    } else if (this.nameTextFieldTarget.value === this.reductionTranslationValue) {
      this.nameTextFieldTarget.value = this.autoReductionTranslationValue
      this.nameFieldTarget.value = this.autoReductionTranslationValue
    }
  }

  updateTabsSources(event) {
    this.frameTargets.forEach(frame => {
      const newUrl = new URL(frame.getAttribute('src'))
      let model = newUrl.searchParams.get('model')

      // switch model query param on toggle button click
      if (model === 'reduction_step_by_step') {
        model = 'auto_reduction_step_by_step'
      } else if (model === 'auto_reduction_step_by_step') {
        model = 'reduction_step_by_step'
      }

      newUrl.searchParams.set('model', model)
      frame.setAttribute('src', newUrl.toString());
    })
  }
}
