import {Controller} from '@hotwired/stimulus'
import {get} from "@rails/request.js";

export default class extends Controller {
  static targets = [ "groupCheckbox", "revealButton", "selectAllCheckbox", "groupsContainer", "roundingRangesField", "submit", "defaultOption", "settings", "copySettings", "menu", "bulkUpdateRoundingField" ]
  static values = {
    action: String,
    base: String
  }

  connect() {
    this.toggleCheckboxes()
  }

  updateAll() {
    let fragment = document.createDocumentFragment(); // for optimization

    for (const checkbox of this.groupCheckboxTargets) {
      if (this.selectAllCheckboxTarget.checked ^ checkbox.checked) {
        checkbox.checked = !checkbox.checked
        const input = this.createOrRemoveId(checkbox)
        if (input) {
          fragment.appendChild(input)
        }
      }
    }

    this.groupsContainerTarget.appendChild(fragment)
  }

  update(event) {
    const input = this.createOrRemoveId(event.target)
    if (input) {
      this.groupsContainerTarget.appendChild(input)
    }
    this.selectAllCheckboxTarget.checked = this.allGroupCheckboxesChecked()
  }

  createOrRemoveId(checkbox) {
    if (checkbox.checked) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.value = checkbox.dataset.groupId
      input.id = `group_${checkbox.dataset.groupId}`
      input.name = 'groups[]'

      return input
    } else {
      document.getElementById(`group_${checkbox.dataset.groupId}`).remove()
    }
  }

  allGroupCheckboxesChecked() {
    let retVal
    this.groupCheckboxTargets.every(checkbox => {
      return retVal = checkbox.checked
    })
    return retVal
  }

  toggle() {
    this.toggleRoundingSettings()
    this.repaintButton()
    this.toggleCheckboxes()
    this.toggleroundingRangesFields()
    this.toggleBulkUpdateFields()
  }

  repaintButton() {
    if (this.revealButtonTarget.classList.contains("btn-gray-pushed")) {
      this.revealButtonTarget.classList.replace("btn-gray-pushed", "btn-gray")
    } else {
      this.revealButtonTarget.classList.replace("btn-gray", "btn-gray-pushed")
    }
  }

  // This is toggled manually and not via reveal component as rest of elements, because it would interfere with another
  // reveal scope
  toggleCheckboxes() {
    this.groupCheckboxTargets.forEach(checkbox => {
      if (this.settingsTarget.classList.contains("hidden")) {
        checkbox.classList.add("hidden")
      } else {
        checkbox.classList.remove("hidden")
      }
    })
  }

  toggleRoundingSettings() {
    if (this.settingsTarget.classList.contains('hidden')) {
      this.settingsTarget.classList.remove('hidden')
      this.menuTarget.innerHTML = this.actionValue
    } else {
      this.hide()
      this.menuTarget.innerHTML = this.baseValue
    }
  }

  hide() {
    this.settingsTarget.classList.add('hidden')
  }

  toggleroundingRangesFields() {
    this.roundingRangesFieldTargets.forEach(input => {
      input.value = !(input.value === "true")
    })
  }

  toggleBulkUpdateFields() {
    this.bulkUpdateRoundingFieldTargets.forEach(input => {
      input.value = !(input.value === "true")
    })
  }

  checkBulkUpdate() {
    if ((this.hasSettingsTarget && !this.settingsTarget.classList.contains('hidden')) || (this.hasCopySettingsTarget && !this.copySettingsTarget.classList.contains('hidden'))) {
      this.groupCheckboxTargets.forEach(checkbox => {
        checkbox.classList.remove("hidden")
      })
    } else {
      this.groupCheckboxTargets.forEach(checkbox => {
        checkbox.classList.add("hidden")
      })
    }
  }
}
